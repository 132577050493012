/*
 * 업무구분: 신탁>고객/상품>고객정보등록>개인고객등록>대리인등록_P
 * 화 면 명: MSPTS105P
 * 화면설명: 대리인등록 POPUP
 * 작 성 일: 2023.04.07
 * 파 일 명: TPUP96000.xfdl
 * 작 성 자: 지재호
 */
<!-- popup -->
<template>
  <mo-modal class="fts-modal large" ref="modal" title="대리인등록">
    <template>
      <div class="wrap-modalcontents">
        <div class="wrap-table">
          <table class="table col-type col1634">
            <tbody>
              <tr>
                <th> 고객명 </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" disabled v-model="ds_detail01.cnm" />
                    <mo-button class="btn-pop-download" @click="btn_popupTPUP95320_onclick" />
                  </div>
                </td>
                <td colspan="2">
                  <mo-checkbox v-model="chk_agntName" v-if="visible_chk_agntName"> 삭제 </mo-checkbox>
                </td>
              </tr>
              <tr>
                <th> 실명번호종류 </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown class="mw150" disabled
                      :items="cmb_rcnoKindTc" 
                      v-model="ds_detail01.rcnoKindTc"
                      ref="dropdown1"
                      placeholder=" "
                    />
                  </div>
                </td>
                <th> 실명번호 </th>
                <td>
                  <div class="wrap-input row"> 
                    <mo-text-field class="input-long" disabled 
                      v-model="maskRcno"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <th rowspan="3"> 주소 </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" disabled 
                      v-model="hmadPsno"
                    />
                    <mo-text-field class="input-address02" v-if="visibleHomeAddrBldnNo" disabled
                      v-model="ds_detail01.homeAddrBldnNo"
                    />
                  </div>
                </td>
                <th> 본인과의 관계 </th>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" 
                      v-model="ds_detail01.prhsRel"
                    />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" disabled  
                      v-model="ds_detail01.homePsnoAddr" 
                    />
                  </div>
                </td>
                <th> 전화번호 </th>
                <td>
                  <div class="wrap-input row">
                    <!-- <mo-dropdown placeholder=" " :items="homeNum" v-model="ds_detail01.homeTelArno" disabled/> -->
                    <mo-text-field disabled class="input-long" v-model="ds_detail01.homeTelArno" ref="registData" :rules="validateRule" no-shake />
                    <mo-text-field disabled class="input-long" v-model="ds_detail01.homeTelPofn" ref="registData" :rules="validateRule" no-shake />
                    <mo-text-field disabled class="input-long" v-model="ds_detail01.homeTelSeq" ref="registData" :rules="validateRule" no-shake />
                  </div>
                </td>
              </tr>
              <tr>
                <td>
                  <div class="wrap-input row">
                    <mo-text-field class="input-long" v-model="ds_detail01.homeRmnAddr" disabled />
                  </div>
                </td>
                <th> 휴대폰 </th>
                <td>
                  <div class="wrap-input row">
                    <mo-dropdown placeholder=" " :items="phoneNum" v-model="ds_detail01.mvphBusiMnNo" disabled ref="dropdown2" />
                    <mo-text-field disabled class="input-long" v-model="ds_detail01.mvphPofn" ref="registData" :rules="validateRule" no-shake />
                    <mo-text-field disabled class="input-long" v-model="ds_detail01.mvphSeq" ref="registData" :rules="validateRule" no-shake />
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
    <template slot="action">
      <div class="wrap-button button-page-bottom row">
        <mo-button point size="large" @click="fn_close()">닫기</mo-button>
        <mo-button primary size="large" @click="fn_confirm" :disabled="enabledList.btn_confirm==false">{{btn_confirmText}}</mo-button>
      </div>
    </template>

    <!-- alert modal popup -->
    <ts-alert-popup 
      ref="alertPopup"
      :popupObj="pAlertPopupObj"
    ></ts-alert-popup>

    <!-- popup107 고객팝업 TPUP95320.xfdl -->
    <msp-ts-107p
      ref="popup107"
      @ts-alert-popup="fn_AlertPopup"
      @ts-popup107-callback="fn_Popup_CallBack"
      :popupObj="pPopup107Obj"
    ></msp-ts-107p>

  </mo-modal>
</template>
  
<script>
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
***********************************************************************************/
import MSPTS107P from '~/src/ui/ts/MSPTS107P'  // 고객조회
import TSServiceUtil from '~/src/ui/ts/comm/TSServiceUtil'
import TSCommUtil from '~/src/ui/ts/comm/TSCommUtil'
import TSAlertPopup from '~/src/ui/ts/comm/TSAlertPopup'  // Alert 팝업 (공통)
import TSInfoUtil    from '~/src/ui/ts/comm/TSInfoUtil'    


/***********************************************************************************
 * DataSet 영역	                                                                    *
 ***********************************************************************************/
const defaultDsSearch = {  
  csId: '',
  agntCsId: '',
}

const defaultDsDetail = {  
  csId: '',
  prhsRel: '',
  accOpngAthzYn: '',
  appnIndcAthzYn: '',
  bktrStipAthzYn: '',
  accFtrnStipAthzYn: '',
  trstContAthzYn: '',
  trstContChngAthzYn: '',
  trstContTmtlAthzYn: '',
  etcYn: '',
  etcExpl: '',
}

const defaultDsDetail01 = {  
  csId: '',
  rcnoKindTc: '',
  rcno: '',
  mnCntcPlcTc: '',
  hmadPsnoSeq: '',
  homePsnoAddr: '',
  homeRmnAddr: '',
  homeTelArno: '',
  homeTelPofn: '',
  homeTelSeq: '',
  mvphPofn: '',
  mvphSeq: '',
  mvphBusiMnNo: '',
  offcAddrPsnoSeq: '',
  offcPsnoAddr: '',
  offcRmnAddr: '',
  offcTelArno: '',
  offcTelPofn: '',
  offcTelSeq: '',
  etcAddrPsnoSeq: '',
  etcPsnoAddr: '',
  etcRmnAddr: '',
  cnm: '',
  hmadPsno1: '',
  hmadPsno2: '',
  etcAddrPsno1: '',
  etcAddrPsno2: '',
  offcAddrPsno01: '',
  offcAddrPsno02: '',
  homeAddrBldnNo: '',
  offcAddrBldnNo: '',
  etcAddrBldnNo: '',
  prhsRel: '', // 본인과의 관계
  
  // TODO : 주소 관련 변수는 아래를 이용은 고민 
  hmadPsno: '', // 전체 우편번호 
  hmadPsno1: '', // HOME 우편번호1 
  hmadPsno2: '', // HOME 우편번호2 
  homeAddrBldnNo: '', // 건물번호
  hmadPsnoSeq: '', // 
  homeAddrPsnoSeq: '',
  homePsnoAddr: '', // 우편주소 all
  homeRmnAddr: '', // 상세주소(표준주소) // 상세주소 + 부가주소 
  homeStdztDtlad: '', // 상세주소

}

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPTS105P",
  screenId: "MSPTS105P",
  props: {
    popupObj: {type:Object, default:null},
  },
  components: {
    'ts-alert-popup': TSAlertPopup,
    'msp-ts-107p': MSPTS107P,
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
  },
  mounted() {
    this.$bizUtil.insSrnLog("MSPTS105P");
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {

      ds_search: Object.assign({}, defaultDsSearch ),
      ds_detail01: Object.assign({}, defaultDsDetail01 ),
      ds_detail: Object.assign({}, defaultDsDetail ),

      clear: false, 

      valueHomeNum: '010',
      valueHomeNum02: '',
      valueHomeNum03: '',

      valuePhoneNum: '010',
      valuePhoneNum02: '',
      valuePhoneNum03: '',
      validateRule: [
        (v) => !!v || '미입력된 필수 항목이 있습니다!'
      ],
      pPopup107Obj: {}, // 고객 팝업
      valueRadio1: '',

      // 팝업 객체
      pAlertPopupObj: {}, // 공통 객체

      sSvcID: '', 		
      cnm: '', 
      AgntCsID: '', 
      prosGb: '', 	 // U, S, I //조회구분을 부모화면에서 받음
      modifyGb: '',
      csID: '',	
      corpYn: '', // 법인구분
      gErpYn: '', // ERP여부
      gErpName: '', // ERP Name

      ds_cmb5104: {
        cNm: '',
        c: '',
      },
      ds_9998: {
        eaiSystemName: '',
      },

      Chk_accOpngAthzYn: '', // 모든 사항을 위임함
      Chk_appnIndcAthzYn: '', // 계좌개설
      Chk_bktrStipAthzYn: '', // 은행입출금계좌등록
      Chk_accFtrnStipAthzYn: '', // 제변경신청
      Chk_trstContAthzYn: '', // 신탁계약
      Chk_trstContChngAthzYn: '', // 신탁계약변경
      Chk_trstContTmtlAthzYn: '', // 신탁계약해지
      Chk_etcYn: '', // 기타
      edt_etcExpl: '',

      chk_agntName: false, // 대리인 삭제
      corpYn:'',

      edt_psno1: '',
      edt_psnoAddr1: '',
      edt_rmnAddr1 : '',
      hid_edt_addrBldnNo: '',

      edt_telArno:'', // 전화번호1
      edt_telPofn:'', // 전화번호2
      edt_telSeq:'',  // 전화번호3

      maskRcno: '',

      btn_popupTPUP95320_enable: false,
      visibleHomeAddrBldnNo: false,
      visible_chk_agntName: true,

      // Contents Model 
      eaiCommObj: TSCommUtil.gfn_eaiCommObj(this),

      /**
         * 공통코드 selectbox data 
         */
      cmb_rcnoKindTc: [],    // 실명종류                 
      rcnoKindTc: '01', 
      cnm: '',

      btn_confirmText: '확인',
      result: {}, // 조회 결과 리턴 

      enabledList: {
        btn_popupTPUP95320: '',
        cmb_rcnoKindTc: '',
        edt_rcno: '',
        edt_prhsRel: '',
        edt_psno1: '',
        edt_psnoAddr1: '',
        edt_rmnAddr1: '',
        edt_telArno: '',
        edt_telPofn: '',
        edt_telSeq: '',
        edt_telArno1: '',
        edt_telPofn1: '',
        edt_telSeq1: '',
        Chk_accOpngAthzYn: '',
        Chk_appnIndcAthzYn: '',
        Chk_bktrStipAthzYn: '',
        Chk_accFtrnStipAthzYn: '',
        Chk_trstContAthzYn: '',
        Chk_trstContChngAthzYn: '',
        Chk_trstContTmtlAthzYn: '',
        Chk_etcYn: '',
        edt_etcExpl: '',
        btn_confirm: true,
      },

      
    };
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    // modal
    modal() {return this.$refs.modal},

    homeNum() {
      let rtn = [];
      rtn.push({value: '010', text: '010'});
      rtn.push({value: '02', text: '02'});
      rtn.push({value: '070', text: '070'});
      return rtn;
    },
    phoneNum() {
      let rtn = [];
      rtn.push({value: '010', text: '010'});
      rtn.push({value: '02', text: '02'});
      rtn.push({value: '070', text: '070'});
      return rtn;
    },

    hmadPsno: {
      set: function() {
      },
      get: function() {
        return this.ds_detail01.hmadPsno1 + this.ds_detail01.hmadPsno2
      }
    },
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {
    
    //대리인 삭제 체크박스 클릭
    chk_agntName (val){
      this.corpYn = "";
      // 삭제 체크 시 화면 all clear
      if ( val == true ) {

        this.ds_detail01 = Object.assign({}, defaultDsDetail01)
        this.ds_detail = Object.assign({}, defaultDsDetail)
        this.maskRcno = '' // 마스킹 실명번호
        
        this.edt_psno1 = ''
        this.edt_psnoAddr1 = ''
        this.edt_rmnAddr1 = ''
        this.enabledList.btn_popupTPUP95320 = false
        
        // 위임내용도 모두 삭제
        this.Chk_accOpngAthzYn = false
        this.Chk_appnIndcAthzYn = false
        this.Chk_bktrStipAthzYn = false
        this.Chk_accFtrnStipAthzYn = false
        this.Chk_trstContAthzYn = false
        this.Chk_trstContChngAthzYn = false
        this.Chk_trstContTmtlAthzYn = false
        this.Chk_etcYn = false
        // this.edt_etcExpl = '' // 안쓰는거 같음
        // this.edt_etcExpl.set_enable(false); // 안쓰는거 같음

      } else {
        this.enabledList.btn_popupTPUP95320 = true
      }
    },

  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    fn_Popup_CallBack(pData) {
      
      console.log('고객조회 callback...')
      console.log(pData)

      this.cnm = pData.cnm
      this.corpYn =  pData.corpYn
      this.AgntCsID = pData.csId
      //this.alert("modifyGb : " + modifyGb);
      if ( this.modifyGb == "50" ) {
        this.visible_chk_agntName = true
      } else {
        this.visible_chk_agntName = false
      }
      this.fn_searchList2();

      // switch (type) {
      //     case '': // 고객조회
      //       console.log('고객조회 callback...')
      //       console.log(pData)

      //       this.cnm = pData.cnm
      //       this.corpYn =  pData.corpYn
      //       this.AgntCsID = pData.csId
      //       //this.alert("modifyGb : " + modifyGb);
      //       if ( this.modifyGb == "50" ) {
      //         this.chk_agntName = true
      //       } else {
      //         this.chk_agntName = false
      //       }
      //       this.fn_searchList2();

      //       break
      //     case 1: // 주소
      //       console.log('주소 callback...')
      //       // 0.우편번호1 / 1.우편번호2 / 2.우편주소(all) / 3.우편번호(all) 
      //       // 4.시도 / 5.시동구 / 6.읍면동 / 7.상세주소 / 8.우편일련번호 / 9.상세주소(DB주소) 
      //       // 10. 상세주소(표준주소) / 11.주소표준화여부 / 12.건물번호(차후적용)
                  
      //       this.edt_psno1.set_value(this.pv_popupResult.zipCode);			// 우편번호
      //       this.edt_psnoAddr1.set_value(this.pv_popupResult.zipAddr); 		// 우편주소
      //       this.edt_rmnAddr1.set_value(this.pv_popupResult.dtlAddr); 		// 상세주소
      //       this.hid_edt_addrBldnNo.set_value(this.pv_popupResult.bldnNo	); // 건물번호(차후적용)	
      //       break
          
      //   }
    },

    // modal
    fn_Open() {
      console.log(this.popupObj)
      this.modal.open()
      this.fn_afterFormOnload()    
    },
    // fn_Close() {
    //   this.modal.close()
    // },
    onClickSearch(){
      // this.$addSnackbar("click: searchIcon"); // test code
      this.$refs.popup107.fn_Open()
    },
    
    /******************************************************************************
     * Function명 : fn_AlertPopup
     * 설명       : alert popup 호출(공통)
    ******************************************************************************/      
    fn_AlertPopup (type, pPopupObj) {
 
      if( !TSCommUtil.gfn_isNull(pPopupObj) ) {
        // 공통 얼럿 팝업 오브젝트 초기화
        TSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
      }
      this.$refs.alertPopup.fn_Open()
    },

    ///
    /**********************************************************************************
     * gfn_setInitForm의  callback 함수 : 실질적 form_onload 구현
     **********************************************************************************/
    fn_afterFormOnload ()
    {
      
      // 초기화
      this.ds_detail01 =  Object.assign({}, defaultDsDetail01 ),
      this.ds_detail = Object.assign({}, defaultDsDetail ),
      this.maskRcno = ''

      this.btn_popupTPUP95320_enable = false,
      this.visibleHomeAddrBldnNo = false,
      this.visible_chk_agntName = true,

      // this.Chk_accOpngAthzYn = false
      // this.Chk_appnIndcAthzYn = false
      // this.Chk_bktrStipAthzYn = false
      // this.Chk_accFtrnStipAthzYn = false
      // this.Chk_trstContAthzYn = false
      // this.Chk_trstContChngAthzYn = false
      // this.Chk_trstContTmtlAthzYn = false
      // this.Chk_etcYn = false
        
      this.corpYn = "";
        //부모화면에서 보낸 파라미터 받는 방법. this.getOwnerFrame()+paramID
      // trace("csID: "+this.getOwnerFrame().csID);
      // trace("prosGb: "+this.getOwnerFrame().prosGb);
      // trace("modifyGb: "+this.getOwnerFrame().modifyGb);
        //alert(prosGb+"=="+modifyGb+"=="+csID+"//");

      this.csID     = this.popupObj.csID; //부모화면에서 보낸 파라미터값
      this.prosGb 	 = this.popupObj.prosGb; //부모화면에서 보낸 파라미터값
      this.modifyGb = this.popupObj.modifyGb; //부모화면에서 보낸 파라미터값
      this.fn_comCode();

      if(this.prosGb =="I"){ //조회구분이 insert일때
        this.fn_searchList1();
        this.btn_confirmText = '확인'
        this.form_enable();

      }else if(this.prosGb =="U"){
        this.fn_searchList1();
        this.btn_confirmText = '수정'
        this.form_enable();
      }else{
        this.fn_searchList1();
        this.btn_confirmText = '확인'
        this.form_enable();
      }
      
      this.gErpYn = "N";
      this.gErpName = "";
      // ########## ERP_APPLY_START ##################################
        // ERP 판단
        // selectTFBZ99998
      /*서비스 호출시 정보 세팅 */
      // this.ds_9998.clearData();
      // this.ds_9998.addRow();
      this.ds_9998.eaiSystemName = ''
      
      // sSvcID = "selectTFBZ99998" ;
      // var sURL          = "svc::"+sSvcID+".do"; // svc = http://localhost:8080/
      // var sInDatasets   = "tfbz99998VO=ds_9998";  // transaction을 요청할 때 입력값으로 보낼 Dataset 의 ID 들 입니다.
      // var sOutDatasets  = "ds_9998=tfbz99998" ;
      // var sArgument     = "";
      // var sCallbackFunc = "fn_callBack9998"; // transaction의 결과를 돌려줄 Function의 이름입니다.
      // /*transaction을 호출하는 공통함수 */
      // this.gfn_callService(sSvcID, sURL, sInDatasets ,sOutDatasets , sArgument , sCallbackFunc);
      // ########## ERP_APPLY_END ##################################

      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600135_S'
      this.eaiCommObj.params = Object.assign({}, this.ds_9998)
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack9998)

    },

    /**********************************************************************************
     * gfn_callService의 콜백 함수 : transaction 응답처리
     **********************************************************************************/
    // ########## ERP_APPLY_START ##################################
    fn_callBack9998 ( res )
    {
      this.gErpYn = "N";
      this.gErpName = "";
      
      const resData = Object.assign({}, res.data)
      if ( TSCommUtil.gfn_trim(resData.errorCode) != '' ) {
        this.fn_AlertPopup(0,{content : resData.errorMsg}) 
        return false
      }
      
      const t_trnstId = res.trnstId
      
      //this.alert("eaiSystemName : " + this.ds_9998.getColumn(0, "eaiSystemName"));
      this.gErpName = this.ds_9998.eaiSystemName = resData.eaiSystemName
      if ( ! TSCommUtil.gfn_isNull( this.gErpName ) ) {
        if ( this.gErpName == "SAP-PI" ) {
          this.gErpYn = "Y";
        } else {
          this.gErpYn = "N";
        }
      }
      
      if ( this.gErpYn == "N" ) {
        // EAI
        this.visibleHomeAddrBldnNo = false
      } else {
        this.visibleHomeAddrBldnNo = true
      }
    },
    // ########## ERP_APPLY_END ##################################


    /**********************************************************************************
     * prosGb의 구분에따라 조회
     **********************************************************************************/
    fn_searchList1 ()
    {
      //alert(prosGb+"=="+modifyGb+"=="+csID+"//");
      //조회용 dataset에 조회할 항목을 넣어준다.

      this.ds_detail01 =  Object.assign({}, defaultDsDetail01 )
      this.ds_detail = Object.assign({}, defaultDsDetail )
      this.ds_search = Object.assign({}, defaultDsSearch )
      
      //this.ds_search.setColumn(0, "prosGb", prosGb);
      if ( ! TSCommUtil.gfn_isNull ( TSCommUtil.gfn_trim(this.popupObj.csID) ) ) {
        this.ds_searchcsId = this.popupObj.csID //부모화면에서 보낸AgntCsID

        console.log("============fn_searchList===============");
        
        //서버에 호출 할 정보를 셋팅 해준다.
        //   sSvcID        = "selectTPUP96000A";
        // var sURL          = "svc::"+sSvcID+".do";		// svc = http://localhost:8080/
        // var sInDatasets   = "tfcrei=ds_search:";
        // var sOutDatasets  = "ds_detail01=tfccsbRst ds_detail=tfcreiRst"; //ds_detail01 =tfcp10000Rst
        // var sArgument     = "";
        // var sCallbackFunc = "fn_callBack";
        // this.gfn_callService(sSvcID, sURL, sInDatasets ,sOutDatasets , sArgument , sCallbackFunc);

        this.eaiCommObj.lv_vm = this
        this.eaiCommObj.auth = 'S'
        this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600227_S'
        this.eaiCommObj.params = Object.assign({}, this.ds_search)
        TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack)
      }
    },


    /**********************************************************************************
     * prosGb의 구분에따라 조회
     **********************************************************************************/
    fn_searchList2 ()
    {
      //조회용 dataset에 조회할 항목을 넣어준다.
      this.ds_detail01 =  Object.assign({}, defaultDsDetail01 )
      this.ds_detail = Object.assign({}, defaultDsDetail )
      this.ds_search = Object.assign({}, defaultDsSearch )
      this.ds_search.agntCsId = this.AgntCsID

      console.log("============fn_searchList===============");
      
      //서버에 호출 할 정보를 셋팅 해준다.
      //   sSvcID        = "selectTPUP96000B";
      // var sURL          = "svc::"+sSvcID+".do";		// svc = http://localhost:8080/
      // var sInDatasets   = "tfcrei=ds_search:";
      // var sOutDatasets  = "ds_detail01=tfccsbRst"; //ds_detail01 =tfcp10000Rst
      // var sArgument     = "";
      // var sCallbackFunc = "fn_callBack";
      // this.gfn_callService(sSvcID, sURL, sInDatasets ,sOutDatasets , sArgument , sCallbackFunc);

      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600228_S'
      this.eaiCommObj.params = Object.assign({}, this.ds_search)
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack)
    },

    /**********************************************************************************
     * gfn_callService의 콜백 함수 : transaction 응답처리
     **********************************************************************************/
    fn_callBack ( res )
    {
      console.log("==================fn_callBack==================");
      
      const resData = Object.assign({}, res.data)
      if ( TSCommUtil.gfn_trim(resData.errorCode) != '' ) {
        this.fn_AlertPopup(0,{content : resData.errorMsg}) 
        return false
      }

      const t_eaiId = res.commHeaderVO.eaiId
      if ( t_eaiId == 'C392_F10600227_S' ) { //selectTPUP96000A "ds_detail01=tfccsbRst ds_detail=tfcreiRst"; //ds_detail01 =tfcp10000Rst
        Object.assign(this.ds_detail, resData)
        if ( TSCommUtil.gfn_length(resData.tfccsb) > 0 )
          Object.assign(this.ds_detail01, resData.tfccsb[0])
      } else if ( t_eaiId == 'C392_F10600228_S'  ) { // selectTPUP96000B ds_detail01=tfccsbRst"; //ds_detail01 =tfcp10000Rst
        Object.assign(this.ds_detail01, resData)
      }

      if ( TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim(this.ds_detail01.csId) ) ) {
        this.visible_chk_agntName = false
      } else {
        if ( this.modifyGb == "50" ) {
          this.visible_chk_agntName = true
        } else {
          this.visible_chk_agntName = false
        }
      }
      //alert("dddd"+this.ds_detail01.getColumn(0,"hmadPsno2"));
      if(this.ds_detail01.mnCntcPlcTc == 1){//주연락처 기본코드: 자택
        this.edt_psno1 = this.ds_detail01.hmadPsno1+this.ds_detail01.hmadPsno2 //우편번호
        this.edt_psnoAddr1 = this.ds_detail01.homePsnoAddr //주소			
        this.edt_rmnAddr1 = this.ds_detail01.homeRmnAddr //상세주소
        this.hid_edt_addrBldnNo = this.ds_detail01.homeAddrBldnNo //자택주소건물번호
        
        this.edt_telArno = this.ds_detail01.homeTelArno   //전화번호1
        this.edt_telPofn = this.ds_detail01.homeTelPofn   //전화번호2
        this.edt_telSeq = this.ds_detail01.homeTelSeq   //전화번호3
        
      }else if(this.ds_detail01.mnCntcPlcTc == 2){ //주연락처기본코드 :직장
        this.edt_psno1 = this.ds_detail01.offcAddrPsno01+this.ds_detail01.offcAddrPsno02  //우편번호
        this.edt_psnoAddr1 = this.ds_detail01.offcPsnoAddr  //주소			
        this.edt_rmnAddr1 = this.ds_detail01.offcRmnAddr  //상세주소
        this.hid_edt_addrBldnNo = this.ds_detail01.offcAddrBldnNo   //직장주소건물번호
        
        this.edt_telArno = this.ds_detail01.offcTelArno   //전화번호1
        this.edt_telPofn = this.ds_detail01.offcTelPofn   //전화번호2
        this.edt_telSeq = this.ds_detail01.offcTelSeq   //전화번호3
          
      }else{ //기타
        this.edt_psno1 = this.ds_detail01.etcAddrPsno1+this.ds_detail01.etcAddrPsno2  //우편번호
        this.edt_psnoAddr1 = this.ds_detail01.etcPsnoAddr   //주소			
        this.edt_rmnAddr1 = this.ds_detail01.etcRmnAddr   //상세주소	
        this.edt_rmnAddr1 = this.ds_detail01.etcRmnAddr   //상세주소	
        this.hid_edt_addrBldnNo = this.ds_detail01.etcAddrBldnNo  //주소건물번호
      }

      if ( !TSCommUtil.gfn_isNull(this.ds_detail.cOpngAthzYn)){
        if ( this.ds_detail.cOpngAthzYn == "Y" ){
          this.Chk_accOpngAthzYn = true
        } else {
          this.Chk_accOpngAthzYn = false
        }
      } else {
        this.Chk_accOpngAthzYn = false
      }
      
      if ( !TSCommUtil.gfn_isNull(this.ds_detail.appnIndcAthzYn)){
        if ( this.ds_detail.accOpngAthzYn == "Y" ){
          this.Chk_appnIndcAthzYn = true
        } else {
          this.Chk_appnIndcAthzYn = false
        }
      } else {
        this.Chk_appnIndcAthzYn = false
      }
      
      if ( !TSCommUtil.gfn_isNull(this.ds_detail.bktrStipAthzYn)){
        if ( this.ds_detail.bktrStipAthzYn == "Y" ){
          this.Chk_bktrStipAthzYn = true
        } else {
          this.Chk_bktrStipAthzYn = false
        }
      } else {
        this.Chk_bktrStipAthzYn = false
      }
    
      if ( !TSCommUtil.gfn_isNull(this.ds_detail.accFtrnStipAthzYn)){
        if ( this.ds_detail.accFtrnStipAthzYn == "Y" ){
          this.Chk_accFtrnStipAthzYn = true
        } else {
          this.Chk_accFtrnStipAthzYn = false
        }
      } else {
        this.Chk_accFtrnStipAthzYn = false
      }
      
      if ( !TSCommUtil.gfn_isNull(this.ds_detail.trstContAthzYn)){
        if ( this.ds_detail.trstContAthzYn == "Y" ){
          this.Chk_trstContAthzYn = true
        } else {
          this.Chk_trstContAthzYn = false
        }
      } else {
        this.Chk_trstContAthzYn = false
      }
      
      if ( !TSCommUtil.gfn_isNull(this.ds_detail.trstContChngAthzYn)){
        if ( this.ds_detail.trstContChngAthzYn == "Y" ){
          this.Chk_trstContChngAthzYn = true
        } else {
          this.Chk_trstContChngAthzYn = false
        }
      } else {
        this.Chk_trstContChngAthzYn = false
      }
      
      if ( !TSCommUtil.gfn_isNull(this.ds_detail.trstContTmtlAthzYn)){
        if ( this.ds_detail.trstContTmtlAthzYn == "Y" ){
          this.Chk_trstContTmtlAthzYn = true
        } else {
          this.Chk_trstContTmtlAthzYn = false
        }
      } else {
        this.Chk_trstContTmtlAthzYn = false
      }
      
      if ( !TSCommUtil.gfn_isNull(this.ds_detail.etcYn)){
        if ( this.ds_detail.etcYn == "Y" ){
          this.Chk_etcYn = true
        } else {
          this.Chk_etcYn = false
        }
      } else {
        this.Chk_etcYn = false
      }
      
      var rcno = this.ds_detail01.rcno
      this.maskRcno = rcno.substr(0,6)+"-"+rcno.substr(6,1)+"******"
      // this.edt_rcno = set_value(maskRcno);
      
    },


    /**********************************************************************************
     * prosGb의 구분에따라 화면컨트롤
     **********************************************************************************/
    form_enable ()
    {
      if ( this.prosGb =="S" ) {
        this.enabledList.btn_popupTPUP95320 = false
        this.enabledList.cmb_rcnoKindTc = false
        this.enabledList.edt_rcno = false
        this.enabledList.edt_prhsRel = false
        this.enabledList.edt_psno1 = false
        this.enabledList.edt_psnoAddr1 = false
        this.enabledList.edt_rmnAddr1 = false
        this.enabledList.edt_telArno = false
        this.enabledList.edt_telPofn = false
        this.enabledList.edt_telSeq = false
        this.enabledList.edt_telArno1 = false
        this.enabledList.edt_telPofn1 = false
        this.enabledList.edt_telSeq1 = false
        this.enabledList.Chk_accOpngAthzYn = false
        this.enabledList.Chk_appnIndcAthzYn = false
        this.enabledList.Chk_bktrStipAthzYn = false
        this.enabledList.Chk_accFtrnStipAthzYn = false
        this.enabledList.Chk_trstContAthzYn = false
        this.enabledList.Chk_trstContChngAthzYn = false
        this.enabledList.Chk_trstContTmtlAthzYn = false
        this.enabledList.Chk_etcYn = false
        this.enabledList.edt_etcExpl = false
        this.enabledList.btn_confirm = false
      }else{
      
        if( this.prosGb =="U" && this.modifyGb =="50" || this.prosGb =="I" ){
        
          this.enabledList.btn_popupTPUP95320 = true
       
          this.enabledList.cmb_rcnoKindTc = true
          this.enabledList.edt_rcno = true
          this.enabledList.edt_prhsRel = true
          this.enabledList.edt_psno1 = true
          this.enabledList.edt_psnoAddr1 = true
          this.enabledList.edt_rmnAddr1 = true
          this.enabledList.edt_telArno = true
          this.enabledList.edt_telPofn = true
          this.enabledList.edt_telSeq = true
          this.enabledList.edt_telArno1 = true
          this.enabledList.edt_telPofn1 = true
          this.enabledList.edt_telSeq1 = true
          this.enabledList.Chk_accOpngAthzYn = true
          this.enabledList.Chk_appnIndcAthzYn = true
          this.enabledList.Chk_bktrStipAthzYn = true
          this.enabledList.Chk_accFtrnStipAthzYn = true
          this.enabledList.Chk_trstContAthzYn = true
          this.enabledList.Chk_trstContChngAthzYn = true
          this.enabledList.Chk_trstContTmtlAthzYn = true
          this.enabledList.Chk_etcYn = true
          if(this.Chk_etcYn == true ){
            this.edt_etcExpl = true
          }else{
            this.edt_etcExpl = false
          }
          
        }else if( this.prosGb =="U" && this.modifyGb =="60"){
          this.enabledList.btn_popupTPUP95320 = false
          //this.edt_CsId.set_enable(false);
          //this.btn_popupTpup95100_1.set_enable(false);
          this.enabledList.cmb_rcnoKindTc = false
          this.enabledList.edt_rcno = false
          this.enabledList.edt_prhsRel = false
          this.enabledList.edt_psno1 = false
          this.enabledList.edt_psnoAddr1 = false
          this.enabledList.edt_rmnAddr1 = false
          this.enabledList.edt_telArno = false
          this.enabledList.edt_telPofn = false
          this.enabledList.edt_telSeq = false
          this.enabledList.edt_telArno1 = false
          this.enabledList.edt_telPofn1 = false
          this.enabledList.edt_telSeq1 = false
          this.enabledList.Chk_accOpngAthzYn = true
          this.enabledList.Chk_appnIndcAthzYn = true
          this.enabledList.Chk_bktrStipAthzYn = true
          this.enabledList.Chk_accFtrnStipAthzYn = true
          this.enabledList.Chk_trstContAthzYn = true
          this.enabledList.Chk_trstContChngAthzYn = true
          this.enabledList.Chk_trstContTmtlAthzYn = true
          this.enabledList.Chk_etcYn = true

          // 안쓰는거 같음      
          // if ( this.Chk_etcYn == true ) {
          //   this.edt_etcExpl.set_enable(true);
          // }else{
          //   this.edt_etcExpl.set_enable(false);
          // }
          
        }else{
        
          //this.edt_CsId.set_enable(false);
        }
      }
    },

    /**********************************************************************************
     * 확인버튼 클릭시/그리드 더블 클릭시 선택한 Row정보 부모화면에 리턴
     **********************************************************************************/
    fn_confirm ()
    {	
      if ( this.corpYn == "Y" ){
        this.fn_AlertPopup(0,{content : '법인은 입력 불가합니다.'})
        return;
      }
    //-------------------입력값 체크---------------------------//
      if ( this.chk_agntName == false ) {
        if (TSCommUtil.gfn_isNull( this.ds_detail01.cnm ))
        {
          this.fn_AlertPopup(0,{content : '성명을 입력해 주십시오.'})
          return ;
        }
        if (TSCommUtil.gfn_isNull( this.ds_detail01.rcnoKindTc ))
        {
          this.fn_AlertPopup(0,{content : '실명번호종류를 입력해 주십시오.'})
          return ;
        }
        if (TSCommUtil.gfn_isNull( this.ds_detail01.rcno ))
        {
          //this.alert("실명번호를 입력해 주십시오");
          this.fn_AlertPopup(0,{content : '실명번호를 입력해 주십시오.'})
          return ;
        }
      }

      this.result = new Object();
      
      if(!TSCommUtil.gfn_isNull(this.AgntCsID)){
        //this.objRtnArr[0]  = AgntCsID; 	//대리인고객아이디	
        this.result.agntCsId = this.AgntCsID; 	//대리인고객아이디
      }else{
        //this.objRtnArr[0] =this.ds_detail.getColumn(0, "agntCsId");
        this.result.agntCsId = this.ds_detail.agntCsId
      }
      
      if ( this.Chk_accOpngAthzYn == false ){
        this.ds_detail.accOpngAthzYn = 'N'
      } else {
        this.ds_detail.accOpngAthzYn = 'Y'
      }
      if ( this.Chk_appnIndcAthzYn == false ){
        this.ds_detail.appnIndcAthzYn = 'N'
      } else {
        this.ds_detail.appnIndcAthzYn = 'Y'
      }
      if ( this.Chk_bktrStipAthzYn == false ){
        this.ds_detail.bktrStipAthzYn = 'N'
      } else {
        this.ds_detail.bktrStipAthzYn = 'Y'
      }
      if ( this.Chk_accFtrnStipAthzYn == false ){
        this.ds_detail.accFtrnStipAthzYn = 'N'
      } else {
        this.ds_detail.accFtrnStipAthzYn = 'Y'
      }
      if ( this.Chk_trstContAthzYn == false ){
        this.ds_detail.trstContAthzYn = 'N'
      } else {
        this.ds_detail.trstContAthzYn = 'Y'
      }
      if ( this.Chk_trstContChngAthzYn == false ){
        this.ds_detail.trstContChngAthzYn = 'N'
      } else {
        this.ds_detail.trstContChngAthzYn = 'Y'
      }
      if ( this.Chk_trstContTmtlAthzYn == false ){
        this.ds_detail.trstContTmtlAthzYn = 'N'
      } else {
        this.ds_detail.trstContTmtlAthzYn = 'Y'
      }
      if ( this.Chk_etcYn == false ){
        this.ds_detail.etcYn = 'N'
      } else {
        this.ds_detail.etcYn = 'Y'
      }

      this.result.returnType           = true
      this.result.prhsRel              = this.ds_detail.prhsRel
      this.result.accOpngAthzYn        = this.ds_detail.accOpngAthzYn
      this.result.appnIndcAthzYn       = this.ds_detail.appnIndcAthzYn
      this.result.bktrStipAthzYn       = this.ds_detail.bktrStipAthzYn
      this.result.accFtrnStipAthzYn    = this.ds_detail.accFtrnStipAthzYn
      this.result.trstContAthzYn       = this.ds_detail.trstContAthzYn
      this.result.trstContChngAthzYn   = this.ds_detail.trstContChngAthzYn
      this.result.trstContTmtlAthzYn   = this.ds_detail.trstContTmtlAthzYn
      this.result.etcYn                = this.ds_detail.etcYn
      this.result.etcExpl              = this.ds_detail.etcExpl
      
      if ( TSCommUtil.gfn_nvl(this.cnm,  "").length > 0)
      {
    
      } else {
        // 삭제 체크박스 입력 후, 고객 입력하지 않은 상태로 위임내용만 입력한 경우
        if ( TSCommUtil.gfn_nvl(this.cnm,  "").length == 0){
          if ( this.chk_agntName == true ) {
            if ( this.result.accOpngAthzYn == "Y"     || this.result.appnIndcAthzYn == "Y" || this.result.bktrStipAthzYn == "Y"
              || this.result.accFtrnStipAthzYn == "Y" || this.result.trstContAthzYn == "Y" || this.result.trstContChngAthzYn == "Y" || result.trstContTmtlAthzYn == "Y" || result.etcYn == "Y"){
              // this.fn_AlertPopup(0,{content : '대리인 입력 후 위임사항 입력바랍니다.'})
              // this.Chk_accOpngAthzYn = false
              // this.Chk_appnIndcAthzYn = false
              // this.Chk_bktrStipAthzYn = false
              // this.Chk_accFtrnStipAthzYn = false
              // this.Chk_trstContAthzYn = false
              // this.Chk_trstContChngAthzYn = false
              // this.Chk_trstContTmtlAthzYn = false
              // this.Chk_etcYn = false
              // return;
            }
          }
        }
        
        if ( this.result.accOpngAthzYn == "Y"     || this.result.appnIndcAthzYn == "Y" || this.result.bktrStipAthzYn == "Y"
          || this.result.accFtrnStipAthzYn == "Y" || this.result.trstContAthzYn == "Y" || this.result.trstContChngAthzYn == "Y" || result.trstContTmtlAthzYn == "Y" || result.etcYn == "Y"){
          //this.alert("대리인 입력 후 위임사항 입력바랍니다.");
          // this.fn_AlertPopup(0,{content : '대리인 입력 후 위임사항 입력바랍니다.'})
          // this.Chk_accOpngAthzYn = false
          // this.Chk_appnIndcAthzYn = false
          // this.Chk_bktrStipAthzYn = false
          // this.Chk_accFtrnStipAthzYn = false
          // this.Chk_trstContAthzYn = false
          // this.Chk_trstContChngAthzYn = false
          // this.Chk_trstContTmtlAthzYn = false
          // this.Chk_etcYn = false
          // return;
        }
      } 

      if( this.prosGb =="U" && this.modifyGb =="50" || this.prosGb =="I"  ){
        this.result.rcnoKindTc   = this.ds_detail01.rcnoKindTc 	//실명번호종류		
        this.result.rcno         = this.ds_detail01.rcno		
        this.result.mvphBusiMnNo = this.ds_detail01.mvphBusiMnNo	
        this.result.mvphPofn     = this.ds_detail01.mvphPofn
        this.result.mvphSeq      = this.ds_detail01.mvphSeq
        this.result.prhsRel      = this.ds_detail01.prhsRel
        
            
        if(this.ds_detail01.mnCntcPlcTc == 1){//주연락처 기본코드: 자택
          this.result.hmadPsnoSeq  = this.ds_detail01.hmadPsnoSeq  //우편번호
          this.result.homePsnoAddr = this.ds_detail01.homePsnoAddr //주소			
          this.result.homeRmnAddr  = this.ds_detail01.homeRmnAddr  //상세주소
          this.result.homeTelArno  = this.ds_detail01.homeTelArno  //전화번호1
          this.result.homeTelPofn  = this.ds_detail01.homeTelPofn  //전화번호2
          this.result.homeTelSeq   = this.ds_detail01.homeTelSeq //전화번호3
          // ########## ERP_APPLY_START ##################################
          this.result.homeAddrBldnNo  = this.ds_detail01.homeAddrBldnNo  //건물번호
          // ########## ERP_APPLY_END ##################################
            
        }else if(this.ds_detail01.mnCntcPlcTc == 2){ //주연락처기본코드 :직장
          this.result.hmadPsnoSeq  = this.ds_detail01.offcAddrPsnoSeq  //우편번호
          this.result.homePsnoAddr  = this.ds_detail01.offcPsnoAddr  //주소			
          this.result.homeRmnAddr  = this.ds_detail01.offcRmnAddr  //상세주소
          this.result.homeTelArno  = this.ds_detail01.offcTelArno  //전화번호1
          this.result.homeTelPofn  = this.ds_detail01.offcTelPofn  //전화번호2
          this.result.homeTelSeq  = this.ds_detail01.offcTelSeq  //전화번호3
          // ########## ERP_APPLY_START ##################################
          this.result.offcAddrBldnNo  = this.ds_detail01.offcAddrBldnNo  //건물번호	
          // ########## ERP_APPLY_END ##################################
        }else{ //기타
          this.result.hmadPsnoSeq  =this.ds_detail01.etcAddrPsnoSeq  //우편번호
          this.result.homePsnoAddr =this.ds_detail01.etcPsnoAddr //주소			
          this.result.homeRmnAddr  =this.ds_detail01.etcRmnAddr  //상세주소	
          // ########## ERP_APPLY_START ##################################
          this.result.etcAddrBldnNo  = this.ds_detail01.etcAddrBldnNo  //건물번호	
          // ########## ERP_APPLY_END ##################################
          this.result.homeTelArno  = "";
          this.result.homeTelPofn  = "";
          this.result.homeTelSeq   = "";
        }
      }
      
      this.result.cnm = this.cnm
      if ( this.chk_agntName == true ) {
        this.result.agntDelYn  = "Y";
      } else {
        this.result.agntDelYn  = "N";
      }
      
      /*********************************************
       * 부모화면으로 파라미터를 보내는 방법
       * - this.close(sVal) 안에 담는다
       * - 문자열만 가능
       * - Modeless인 경우, this.opener.함수명(보낼데이터)
       *   예) this.opener.fn_popupAfter("popupID","abc1234");
       ********************************************/

      //this.close(this.objRtnArr.toString());
      //this.close(result);
      //this.gfn_popupClose(result);

      this.$emit('ts-popup-callback', 10, this.result)
      this.modal.close()
    },

    /**********************************************************************************
     * 닫기  : 닫기버튼클릭시 호출
     **********************************************************************************/
    fn_close ()
    {
      //그냥 닫을때는 null값으로
      this.result = new Object();
      this.result.returnType           = false;
      // this.gfn_popupClose(result);
      this.$emit('ts-popup-callback', 10, this.result)
      this.modal.close()
      this.closeDropdown()
    },
    closeDropdown() {
      this.$refs.dropdown1.close()
      this.$refs.dropdown2.close()
    },


    /**********************************************************************************
     *팝업화면 
    **********************************************************************************/
    btn_popupTPUP95320_onclick () // 고객팝업
    {
      this.corpYn = "";
      // var oArg           = {pID:"TPUP95320", etcAddrBldnNo:''};   //팝업화면으로 보낼 파라미터(없음).
      // var sOption        = "autosize=true,title=true";           	//팝업열때 옵션
      // var sPopupCallBack = "fn_popupAfter";                      	//팝업화면에서 보낸 파라미터 받는곳
      // this.gfn_openPopup("TPUP95320","popup::TPUP95320.xfdl",oArg,sOption,sPopupCallBack);

      this.pPopup107Obj.pID = 'TPUP95320' //팝업화면으로 보낼 파라미터(없음).
      this.pPopup107Obj.etcAddrBldnNo = '' //팝업화면으로 보낼 파라미터(없음).
      this.$refs.popup107.fn_Open()
    },

    btn_popupTpup95100_onclick ()
    {
      var oArg           = {pID:"TPUP95100", etcAddrBldnNo:''};   //팝업화면으로 보낼 파라미터(없음).
      var sOption        = "autosize=true,title=true";           	//팝업열때 옵션
      var sPopupCallBack = "fn_popupAfter";                      	//팝업화면에서 보낸 파라미터 받는곳
      this.gfn_openPopup("TPUP95100","popup::TPUP95100.xfdl",oArg,sOption,sPopupCallBack);
    },

    /**********************************************************************************
    * 팝업화면에서 보낸 파라미터 받는곳(id, param)
    * param은 문자열만 가능(object으로 받을 수 없음. 즉 Dataset은 안됨)
    **********************************************************************************/
    fn_popupAfter ( strId, strVal )
    {
      this.ds_detail01 = Object.assign({}, defaultDsDetail01)

      let arrVal = new Array(15);
      if(TSCommUtil.gfn_isNull(strVal)){
        return;
      }else{
        arrVal = strVal.split(",");
      }

      if ( strId == "TPUP95100" ) {
        // 0.우편번호1 / 1.우편번호2 / 2.우편주소(all) / 3.우편번호(all) 
        // 4.시도 / 5.시동구 / 6.읍면동 / 7.상세주소 / 8.우편일련번호 / 9.상세주소(DB주소) 
        // 10. 상세주소(표준주소) / 11.주소표준화여부 / 12.건물번호(차후적용)
              
        this.edt_psno1 = this.pv_popupResult.zipCode			    // 우편번호
        this.edt_psnoAddr1 = this.pv_popupResult.zipAddr 		  // 우편주소
        this.edt_rmnAddr1 = this.pv_popupResult.dtlAddr 		  // 상세주소
        this.hid_edt_addrBldnNo = this.pv_popupResult.bldnNo  // 건물번호(차후적용)	
      }
      else if( strId == "TPUP95320"){
        this.cnm = arrVal[2]
        this.corpYn =  arrVal[8];
        this.AgntCsID = arrVal[0];
        
        if ( this.modifyGb == "50" ) {
          this.visible_chk_agntName = true
        } else {
          this.visible_chk_agntName = false
        }
        trace("===AgntCsID=="+this.AgntCsID);
        this.fn_searchList2();
      }
    },

    /***************************************************************************************
      code        : 호출할 공통코드
      dsName      : 호출된 공통코드를 담을 Dataset
      selecttype  : ComboBox 최상단에 추가할 문구( A: -전체-, S:-선택-, N: 빈칸)
      
      selecttype은 추후 변경될 수 있음.
      this.fn_commonAfterOnload 를 화면에 선언하면 gfn_setCommonCode처리후 호출됨.
    *****************************************************************************************/
    fn_comCode ()
    {
      this.eaiCommObj.trnstId = 'txTSSTS90S1' // selectTFCP11000A
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600046_S'
      this.eaiCommObj.params = {'TUKEMK':[
                                  {cId:"510402"},  // cmb_rcnoKindTc 실명종류
                              ]}
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_comCodeCallBack)
    },

    fn_comCodeCallBack ( res ) {
      this.cmb_rcnoKindTc = []
      for ( let index in res.data.tukemk ) {
          let item = res.data.tukemk[index]
          switch (item.cid) 
          {
            case "5104": // 510402
              this.cmb_rcnoKindTc.push({value: item.c , text: item.cnm})
              break
          }
      }
    },

    // // 안쓰는거 같음
    // Chk_etcYn_onclick () // 안쓰는거 같음
    // {
    //   if ( this.Chk_etcYn.value == true ){
    //     this.edt_etcExpl.set_enable(true);
    //   }else{
    //     this.edt_etcExpl.set_enable(false);
    //     this.edt_etcExpl.set_value("");
    //   }
    // },

    // // 안쓰는거 같음
    // Chk_accOpngAthzYn_onchanged ()
    // {
    //   if( this.Chk_accOpngAthzYn.value == true ){
    //     this.Chk_accOpngAthzYn = true
    //     this.Chk_appnIndcAthzYn = true
    //     this.Chk_bktrStipAthzYn = true
    //     this.Chk_accFtrnStipAthzYn = true
    //     this.Chk_trstContAthzYn = true
    //     this.Chk_trstContChngAthzYn = true
    //     this.Chk_trstContTmtlAthzYn = true
    //     this.Chk_etcYn = true
    //     this.Chk_etcYn_onclick();
    //   }else{
    //     this.Chk_accOpngAthzYn = false
    //     this.Chk_appnIndcAthzYn = false
    //     this.Chk_bktrStipAthzYn = false
    //     this.Chk_accFtrnStipAthzYn = false
    //     this.Chk_trstContAthzYn = false
    //     this.Chk_trstContChngAthzYn = false
    //     this.Chk_trstContTmtlAthzYn = false
    //     this.Chk_etcYn = false
    //     // this.edt_etcExpl.set_value(""); // 안씀
    //     // this.edt_etcExpl.set_enable(false); // 안씀
    //   }
    // },

    /***********************************************************************************
        대리인 삭제 체크박스 클릭
    **********************************************************************************/
    chk_agntName_onclick ()
    {
      this.corpYn = "";
      // 삭제 체크 시 화면 all clear
      if ( this.chk_agntName == true ) {

        
        this.ds_detail01 =  Object.assign({}, defaultDsDetail01 )
        this.ds_detail = Object.assign({}, defaultDsDetail )
        
        this.edt_psno1 = ''
        this.edt_psnoAddr1 = ''
        this.edt_rmnAddr1 = ''
        this.enabledList.btn_popupTPUP95320 = false

        this.fn_afterFormOnload()
        
        // 위임내용도 모두 삭제
        // this.Chk_accOpngAthzYn = false
        // this.Chk_appnIndcAthzYn = false
        // this.Chk_bktrStipAthzYn = false
        // this.Chk_accFtrnStipAthzYn = false
        // this.Chk_trstContAthzYn = false
        // this.Chk_trstContChngAthzYn = false
        // this.Chk_trstContTmtlAthzYn = false
        // this.Chk_etcYn = false
        // this.edt_etcExpl = ''
        // this.enabledList.edt_etcExpl = false

      } else {
        this.enabledList.btn_popupTPUP95320 = true
      }
    },


    // methods end
  }
};
</script>
<style scoped>
</style>