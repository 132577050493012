/*
 * 업무구분: 신탁>고객/상품>고객정보등록>개인고객등록>FATCA대상자(개인)확인_P
 * 화 면 명: MSPTS104P
 * 화면설명: FATCA 대상자(개인)확인 POPUP
 * 작 성 일: 2023.04.05
 * 작 성 자: 지재호
 * 파 일 명: TPUP95050
*/
<template>
  <mo-modal class="fts-modal medium" ref="modal" title="FATCA 대상자(개인)확인">
      <template>
        <div class="wrap-modalcontents">
          <div class="wrap-table-title row mt0">
            <span class="red"> ※ FATCA/CRS 본인확인서 대상자입니다. </span>
          </div>
          <div class="wrap-radiobox column gap12">
            <mo-radio v-for="(radio, i) in fatcaRadio" :key="i" v-model="rdo_fatcaGb" :value="radio.value"> {{radio.text}} </mo-radio>
          </div>
        </div>
      </template>
      <template slot="action">
        <div class="wrap-button button-page-bottom row">
          <!-- <mo-button point size="large" @click="closeModal(1)">닫기</mo-button> -->
          <mo-button primary size="large" @click="btn_confirm_onclick">확인</mo-button>
          <!-- <mo-button primary size="large" @click="confirmTest">confirmTest</mo-button> -->
        </div>
      </template>
      <!-- alert modal popup -->
      <ts-alert-popup 
        ref="alertPopup"
        :popupObj="pAlertPopupObj"
      ></ts-alert-popup>
    </mo-modal>    
</template>
<script>

/***********************************************************************************
   * DataSet 영역	                                                                    *
   ***********************************************************************************/
  const defaultDsSearch = {  
    csId: '',
    parentFileNm: '',
  }

  const defaultDsDetail = {  
    admkNm: '',
    clctnDate: '',
    clctnSeq: '',
    rebkAcno01: '',
    rebkAcno02: '',
    btdt: '',
    clctnMedTyp: '',
    taxtYn: '',
    trgpTc: '',
    mktnCnsntYn: '',
    sbmtYn: '',
    natnNatC: '',
    rsdnNatC: '',
    cno: '',
    csId: '',
    cnm: '',
    natnNatCNm: '',
    cdiNatC: '',
    homePsnoAddr: '',
    homeRmnAddr: '',
    hAddr: '',
    cono: '',
    offcPsnoAddr: '',
    offcRmnAddr: '',
    oAddr: '',
    homeTelArno: '',
    homeTelPofn: '',
    homeTelSeq: '',
    hTelno: '',
    offcTelArno: '',
    offcTelPofn: '',
    offcTelSeq: '',
    oTelno: '',
    rcno: '',
    mvphBusiMnNo: '',
    mvphPofn: '',
    mvphSeq: '',
    procCd: '',
  }

/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import TSCommUtil from '~/src/ui/ts/comm/TSCommUtil'
import TSServiceUtil from '~/src/ui/ts/comm/TSServiceUtil'
import TSAlertPopup from '~/src/ui/ts/comm/TSAlertPopup'  // Alert 팝업 (공통)

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPTS114P",
  screenId: "MSPTS114P",
  props: {
    popupObj: {type:Object, default:null},
  },
  components: {
    'ts-alert-popup': TSAlertPopup,
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    // this.fn_afterFormOnload()
  },
  mounted() {
    this.$bizUtil.insSrnLog("MSPTS114P");
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      // 팝업 객체
      pAlertPopupObj: {}, // 공통 객체
      fatca: null,
      rdo_fatcaGb: 'P0',
      fatcaRadio: [
        {
          value: 'P0',
          text: '아래 해당 사항 없음'
        },
        {
          value: 'P1',
          text: '미국 시민권자(이중국적자 포함)'
        },
        {
          value: 'P2',
          text: '미국 영주권자'
        },
        {
          value: 'P3',
          text: '미국세법상 미국거주자'
        },
        {
          value: 'P4',
          text: '한국, 미국 이외의 조세목적상 해외 거주자'
        },
      ],
      sSvcID: '', // 서비스 id
      gCsId: '', // 부모화면에서 보낸 고객ID
      gParentFileNm: '', // 부모화면에서 보낸 화면(파일)명
      usaChkResult: '', // 리턴값 : true / false
      csId: '',
      parentFileNm: '',

      // Contents Model 
      eaiCommObj: TSCommUtil.gfn_eaiCommObj(this),

      ds_search: Object.assign({}, defaultDsSearch),
      ds_detail: Object.assign({}, defaultDsDetail),

    };
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    // modal
    modal() {return this.$refs.modal},
    select01() {
      let rtn = [];
      rtn.push({value: '0', text: '사유 01'});
      rtn.push({value: '1', text: '사유 02'});
      return rtn;
    }
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {},
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    confirmTestCallBack(boolean) {
      console.log('----confirmTestCallBack----')
      console.log(boolean)
    },
    confirmTest(){
      console.log('---팝업 시작전----')
      // this.fn_AlertPopup(0,{ content : '팝업 콜백 테스트', confirm: true, confirmFunc: confirmCall, closeFunc: confirmCall } )
      this.$emit('ts-alert-popup', 0, { content : '팝업 콜백 테스트', confirm: true, confirmFunc: this.confirmTestCallBack, closeFunc: this.confirmTestCallBack })
      console.log('---팝업 시작후----')
    },
    
    // modal
    openModal(type) {
      switch (type) {
        case 1:
          this.modal.open();
          break;
      }
    },
    closeModal(type) {
      switch (type) {
        case 1:
          this.$emit('ts-popup-callback', 9, {isConfirm: false}) 
          this.modal.close();
          break;
      }
    },

    fn_Open() {
      console.log(this.popupObj)
      this.fn_afterFormOnload()
      this.modal.open()
    },
    fn_Close() {
      this.modal.close()
    },

    /**********************************************************************************
     * gfn_setInitForm의  callback 함수 : 실질적 form_onload 구현
     **********************************************************************************/
    fn_afterFormOnload ()
    {
      //this.sta_cbMsg.set_text("");	//팝업 메시지표시바 초기화
      this.ds_detail = Object.assign({}, defaultDsDetail)
      this.ds_search = Object.assign({}, defaultDsSearch)
      //부모화면에서 보낸 파라미터 받는 방법. this.getOwnerFrame()+paramID 	
      this.gCsId = this.popupObj.csId; //부모화면에서 보낸 파라미터값
      this.gParentFileNm = this.popupObj.parentFileNm; //부모화면에서 보낸 파라미터값

      this.csId = this.gCsId
      this.parentFileNm = this.gParentFileNm
      Object.assign(this.ds_search, this.popupObj)
      this.usaChkResult = false;
    }, 

    /***************************************************************************************
      확인 버튼 클릭 -> 확인버튼 클릭 시 저장 && 인터페이스 호출
    *****************************************************************************************/
    async btn_confirm_onclick ()
    {
      /*서비스 호출시 정보 세팅 */
      if ( TSCommUtil.gfn_isNull ( this.csId ) )
      {
        let t_popupObj = {
          confirm: false,
          content: '고객ID 입력오류입니다.'
        }
        this.$emit('ts-alert-popup', 0, t_popupObj)
        return false;
      }
      
      if ( TSCommUtil.gfn_isNull( this.rdo_fatcaGb ) )
      {
        //TSCommUtil.gfn_validate(lv_vm, modelObj.title+"은(는) 필수입력 항목입니다.")
        let t_popupObj = {
          confirm: false,
          content: '대상자구분을 선택해주십시오.'
        }
        this.$emit('ts-alert-popup', 0, t_popupObj)
        return false;
      }
      
      //선택값이 '해당사항없음'일 시 신탁 국적 or 거주국 이 미국인지 여부 체크
      if ( this.rdo_fatcaGb == "P0" ) {
        /*서비스 호출시 정보 세팅 */

        // sSvcID = "selectTPUP95050";
        // var sURL          = "svc::"+sSvcID+".do";	   // svc = http://localhost:8080/
        // var sInDatasets   = "tpup95050VO=ds_search:";  // transaction을 요청할 때 입력값으로 보낼 Dataset 의 ID 들 입니다.
        // var sOutDatasets  = "ds_detail=tpup95050Rst";  // transaction을 처리 결과를 받을 Dataset의 ID 들입니다.
        // var sArgument     = "";
        // var sCallbackFunc = "fn_callBackUsaChk"; // transaction의 결과를 돌려줄 Function의 이름입니다.
        // /*transaction을 호출하는 공통함수 */
        // this.gfn_callService(sSvcID, sURL, sInDatasets ,sOutDatasets , sArgument , sCallbackFunc);

        // TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBackUsaChk)

        try {
          // 고객의 국적체크
          this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600201_S'
          this.eaiCommObj.params = {}
          Object.assign(this.eaiCommObj.params, this.ds_search)
          let res = await TSServiceUtil.invokeAsync(this.eaiCommObj)
          let resData = Object.assign({}, res.data)
          if ( TSCommUtil.gfn_trim(resData.errorCode) != '' ) {
            this.fn_AlertPopup(0,{content : resData.errorMsg}) 
            return false
          }
          Object.assign(this.ds_detail, res.data)
          const resFatca = await this.fn_insertFatca ( res )  
          if ( TSCommUtil.gfn_trim(resFatca.data.errorCode) != '' ) {
            this.fn_AlertPopup(0,{content : resFatca.data.errorMsg}) 
            return false
          }
          Object.assign(this.ds_detail, resFatca.data)
          if( this.ds_detail.natnNatC == "US" || this.ds_detail.rsdnNatC == "US"){
            
            this.$emit('ts-alert-popup', 0, { content : ["국적이나 거주국이 미국으로 등록된 고객이십니다.", "계속해서 '해당사항없음' 으로 진행하시겠습니까?"]
                      , confirm: true
                      , confirmFunc: this.fn_callBackResponse
                      , closeFunc: this.fn_callBackResponse 
                    }
            )
          }
          else
            this.fn_callBackResponse ( true )

        } catch (error) {
          // 에러 핸들러 쓸지 말지는 각자
          TSServiceUtil.errorHandlerComm(this, error)
          // FSServiceUtil.errorHandlerComm(context, {msgCd: errorObj.msgCd, msgDesc: errorObj.msgDesc}, errorHandler)
        }

      } else {
        this.ds_detail = Object.assign({}, defaultDsDetail)
        this.ds_detail.trgpTc = this.rdo_fatcaGb
        this.ds_detail.csId = this.csId
        
        // sSvcID = "insertTPUP95050A" ;
        // var sURL          = "svc::"+sSvcID+".do"; // svc = http://localhost:8080/
        // var sInDatasets   = "tpup95050=ds_detail tpup95050VO=ds_search:";
        // var sOutDatasets  = "ds_detail=tpup95050Rst";
        // var sArgument     = "";
        // var sCallbackFunc = "fn_callBackA";
        // /*transaction을 호출하는 공통함수 */
        // this.gfn_callService(sSvcID, sURL, sInDatasets ,sOutDatasets , sArgument , sCallbackFunc, true, false);
        
        const resFatca = await this.fn_insertFatca ()
        this.fn_callBackA ( resFatca ) 
      }
    },

    /**********************************************************************************
     * gfn_callService의 콜백 함수 : fn_callBackUsaChk 응답처리
     **********************************************************************************/
    async fn_insertFatca ()
    {
      this.ds_detail.trgpTc = this.rdo_fatcaGb
      this.ds_detail.csId = this.csId
    
      // sSvcID = "insertTPUP95050A" ;
      // var sURL          = "svc::"+sSvcID+".do"; // svc = http://localhost:8080/
      // var sInDatasets   = "tpup95050=ds_detail tpup95050VO=ds_search:";
      // var sOutDatasets  = "ds_detail=tpup95050Rst";
      // var sArgument     = "";
      // var sCallbackFunc = "fn_callBack";
      
      // /*transaction을 호출하는 공통함수 */
      // this.gfn_callService(sSvcID, sURL, sInDatasets ,sOutDatasets , sArgument , sCallbackFunc, true, false);
      
      this.eaiCommObj.commHeaderVO.eaiId = 'C392_F10600200_S'
      this.eaiCommObj.params = {}
      this.eaiCommObj.params = this.ds_detail
      this.eaiCommObj.params.TPUP95050 = [this.ds_search]
      return await TSServiceUtil.invokeAsync(this.eaiCommObj)
    },

    /**********************************************************************************
     * gfn_callService의 콜백 함수 : fn_callBack 응답처리
     **********************************************************************************/
    fn_callBack ( res )
    {
      const resData = Object.assign({}, res.data)
      if ( TSCommUtil.gfn_trim(resData.errorCode) != '' ) {
        this.fn_AlertPopup(0,{content : resData.errorMsg}) 
        return false
      }
      Object.assign(this.ds_detail, resData)
      if( this.ds_detail.natnNatC == "US" || this.ds_detail.rsdnNatC == "US"){
        
        this.$emit('ts-alert-popup', 0, { content : ["국적이나 거주국이 미국으로 등록된 고객이십니다.", "계속해서 '해당사항없음' 으로 진행하시겠습니까?"]
                  , confirm: true
                  , confirmFunc: this.fn_callBackResponse
                  , closeFunc: this.fn_callBackResponse 
                }
        )
      }
      else
        this.fn_callBackResponse ( true )
    },

    /**********************************************************************************
     * fn_callBack 의 콜백 함수 : fn_callBackResponse 응답처리
     **********************************************************************************/
    async fn_callBackResponse ( pBool ) { // true || false
      console.log('-----fn_callBackResponse-----')
      this.usaChkResult = pBool

      let rData = {
        type: this.popupObj.type,
        usaChkResult: this.usaChkResult,
        isConfirm: true, // 확인 버튼 클릭
        fatcaGb: this.rdo_fatcaGb, // 선택값
        ds_search: this.ds_search, 
      }

      if ( !this.usaChkResult ){
        this.$emit('ts-popup-callback', 9, rData)
        this.fn_Close()
      }else{
        this.ds_detail = Object.assign({}, defaultDsDetail),
        this.ds_detail.trgpTc = this.rdo_fatcaGb 
        this.ds_detail.csId = this.csId
        
        // sSvcID = "insertTPUP95050A" ;
        // var sURL          = "svc::"+sSvcID+".do"; // svc = http://localhost:8080/
        // var sInDatasets   = "tpup95050=ds_detail tpup95050VO=ds_search:";
        // var sOutDatasets  = "ds_detail=tpup95050Rst";
        // var sArgument     = "";
        // var sCallbackFunc = "fn_callBackA";
        // /*transaction을 호출하는 공통함수 */
        // this.gfn_callService(sSvcID, sURL, sInDatasets ,sOutDatasets , sArgument , sCallbackFunc, true, false);
       
        const resFatca = await this.fn_insertFatca ()
        this.fn_callBackA ( resFatca ) 
      }
    },

    /**********************************************************************************
     * gfn_callService의 콜백 함수 : fn_callBack 응답처리
     **********************************************************************************/
    fn_callBackA ( res )
    {
      const t_trnstId = res.trnstId
      const resData = Object.assign({}, res.data)

      if ( this.rdo_fatcaGb == "P1" || this.rdo_fatcaGb == "P2" 
          || this.rdo_fatcaGb == "P3" || this.rdo_fatcaGb == "P4") {
      
        this.usaChkResult = false
        this.$emit('ts-alert-popup', 0, { 
            content : "U-Portal FATCA/CRS정보 입력 후 진행 가능합니다. (U-Portal->개인고객정보->고객정보등록->하단[FATCA/CRS정보] 버튼)"
            , confirm: true
            , confirmFunc: this.fn_callBackA_Response
            , closeFunc: this.fn_callBackA_Response
          }
        )

        /*********************************************
         * 부모화면으로 파라미터를 보내는 방법
         * - this.close(sVal) 안에 담는다
         * - 문자열만 가능
         * - Modeless인 경우, this.opener.함수명(보낼데이터)
         *   예) this.opener.fn_popupAfter("popupID","abc1234");
         ********************************************/
        // usaChkResult = false;
        // this.fn_print();
        // this.close(usaChkResult);
      } else {
        this.usaChkResult = true
        // this.fn_print();
        this.fn_callBackA_Response()
      }
    },

    /**********************************************************************************
     * fn_callBackA 콜백 함수 : fn_callBackA_Response 응답처리
     **********************************************************************************/
    fn_callBackA_Response() {
      // this.fn_print();
      //this.close(usaChkResult);
      let rData = {
        type: this.popupObj.type,
        usaChkResult: this.usaChkResult,
        isConfirm: true, // 확인 버튼 클릭 
        fatcaGb: this.rdo_fatcaGb, // 선택값
        ds_search: this.ds_search,
      }
      this.$emit('ts-popup-callback', 9, rData)
      this.fn_Close() 
    },


    /**********************************************************************************
     * 출력처리 
     **********************************************************************************/
    fn_print ()
    {
      //this.alert("출력처리");
      //this.alert("this.rdo_fatcaGb.value : " + this.rdo_fatcaGb.value);
      if ( this.rdo_fatcaGb == "P0" ) {	//'해당없음'이면 사전본인확인서 출력
    // 		var arg  = " vflag="+ quote("FATCA_CHK");
    //         arg += " report=" + quote("FATCA_before.jrf"); 
    //        
    // 		dialog("SY::G_REPORT_Ubi.xml", arg, 900, 720 , true, -1, -1);  
        var reportMrdNm = "/cronix/fiduciary/popup/TPUP95050.mrd"; // 보고서 파일 경로
        //var reportUrl    = "";//"reportTFCP11000.do"; // 보고서 호출 서비스url
        //var reportParam  = "";
        var reportUrl    = "reportTPUP95050.do"; // 보고서 호출 서비스url
        var reportParam  = this.ds_search;
        
        //보고서 출력 함수
        this.gfn_reportOpen(reportMrdNm,reportUrl,reportParam);
        
      } else {									//'대상'이면 사전본인확인서, FATCA/CRS확인서 출력
      // !! TOBE에서 삭제
    // // 		var arg  = " vflag="+ quote("FATCA_CHK");
    // //         arg += " report=" + quote("FATCA_before.jrf");
    // //        
    // // 		dialog("SY::G_REPORT_Ubi.xml", arg, 900, 720 , true, -1, -1); 
    // // 		
    // // 		var arg  = " vflag="+ quote("FATCA_CHK");
    // // 			arg += " report=" + quote("FATCA_pers.jrf");
    // //        
    // // 		dialog("SY::G_REPORT_Ubi.xml", arg, 900, 720 , true, -1, -1); 
    //     var reportMrdNm = "/cronix/fiduciary/popup/TPUP95051.mrd"; // 보고서 파일 경로 
    //     //var reportUrl   = "";//"reportTFCP11000.do"; // 보고서 호출 서비스url
    //     //var reportParam = "";// = this.ds_search;
    //     var reportUrl    = "reportTPUP95050.do"; // 보고서 호출 서비스url
    //     var reportParam  = this.ds_search;
        
    //     //보고서 출력 함수
    //     this.gfn_reportOpen(reportMrdNm,reportUrl,reportParam);
      }
    },

    fn_error (res) {
      console.log("에러 콜백")
      let msg = ''
      if ( res.msgDesc ) {
        msg = res.msgDesc
      } else {
        msg = res
      }
      this.fn_AlertPopup(0,{ content : msg} )
      // this.ds_detail = this.ds_detail,res.data.body
    } ,

    /******************************************************************************
     * Function명 : fn_AlertPopup
     * 설명       : alert popup 호출(공통)
    ******************************************************************************/      
    fn_AlertPopup (type, pPopupObj) {
      switch (type) {
        case 0:
          if( !TSCommUtil.gfn_isNull(pPopupObj) ) {
            // 공통 얼럿 팝업 오브젝트 초기화
            TSInfoUtil.initAlertPopObj(type, this.pAlertPopupObj, pPopupObj)
          }
          break
      }
      this.$refs.alertPopup.fn_Open()
    },

  }
};
</script>
