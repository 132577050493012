/* * 업무구분: 고객 * 화 면 명: MSPTS109P * 화면설명: 마케팅동의_P * 작 성 일: 2023.04.10 * 작 성 자: 지재호 
 * 수정일 : 2024-03-06 정수진 사유 : ASR240300447 / [사랑on신탁] 태블릿 전자신규매핑 및 프로그램 수정
 */ 

<template>
  <mo-modal class="fts-modal large" ref="modal" title="마케팅동의">
    <template>
      <div class="wrap-modalcontents">
        <h3>마케팅 동의 연락방식 선택</h3>
        <div class="wrap-table mt-3">
          <table class="table col-type">
            <tbody>
              <tr>
                <th>
                  <span> 연락방식 </span>
                </th>
                <td>
                  <div class="flex-sb">
                    <div class="wrap-input row">
                      <mo-checkbox v-model="Chk_mkt_all" class="row" @click="fn_onClickMktAll"> 전체 </mo-checkbox>
                    </div>
                    <div class="wrap-input wrap-switch row">
                      <div class="row">
                        <span class="title-switch"> 전화 </span>
                        <mo-switch v-model="mktSwtcTel" @input="fn_changeCobMktCnvs" class="switch-t1" />
                      </div>
                      <div class="row">
                        <span class="title-switch"> 문자 </span>
                        <mo-switch v-model="mktSwtcSs" @input="fn_changeCobMktCnvs" class="switch-t1" />
                      </div>
                      <div class="row">
                        <span class="title-switch"> E-mail </span>
                        <mo-switch v-model="mktSwtcEMail" @input="fn_changeCobMktCnvs" class="switch-t1" />
                      </div>
                      <div class="row">
                        <span class="title-switch"> 우편 </span>
                        <mo-switch v-model="mktSwtcMail" @input="fn_changeCobMktCnvs" class="switch-t1" />
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <th>
                  <span> 거래여부 </span>
                </th>
                <td>
                  <!-- ASR240300447 / [사랑on신탁] 태블릿 전자신규매핑 및 프로그램 수정 / 2024.03.13 -->
                  <mo-radio-wrapper :items="mktCustDealyn" v-model="ds_detail.trCsYn" class="row" />
                </td>
              </tr>
              <tr>
                <th>
                  <span> 유효기간 </span>
                </th>
                <td>
                  <!-- DEFAULT : 거래 고객의 경우 -->
                    <div class="radio row">
                      <mo-radio-wrapper :items="dsAvlPrd1" v-model="ds_detail.mktnCnsntTrm" class="row" />
                    </div>
                    <!-- ASR240300447 / [사랑on신탁] 태블릿 전자신규매핑 및 프로그램 수정 / 2024.03.13 -->
                  
                </td>
              </tr>
              <tr>
                <th>
                  <span> 종료일자 </span>
                </th>
                <td>
                  <mo-date-picker v-model="fn_valdEndDate" :bottom="false" />
                  <!-- ASR240300447 / [사랑on신탁] 태블릿 전자신규매핑 및 프로그램 수정 / 2024.03.13 -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </template>
    <template slot="action">
      <div class="wrap-button button-page-bottom row">
        <mo-button size="large" @click="fn_Close">닫기</mo-button>
        <mo-button primary size="large" @click="fn_confirm">확인</mo-button>
      </div>
    </template>
  </mo-modal>
</template>
<script>
/***********************************************************************************
 * DataSet 영역	                                                                    *
 ***********************************************************************************/
const defaultDsDetail = {
  mktnCnsntTrm: '', // 마케팅동의기간
  valdEndDate: '', // 유효종료일자
  mktnInvtTelYn: '', // 마케팅권유전화여부
  mktnInvtSsYn: '', // 마케팅권유SMS여부
  mktnInvtEmailYn: '', // 마케팅권유이메일여부
  mktnInvtMailYn: '', // 마케팅권유우편여부
  trCsYn: '', // 거래고객여부
  mktnCnsntDate: '', // 마케팅 동의일자(=sysdate)
  csId: '',
  newMemberYn: '' // 신규고객 여부
}
/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	 d                                                  *
 ***********************************************************************************/
import moment from 'moment'
import TSCommUtil from '~/src/ui/ts/comm/TSCommUtil'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: 'MSPTS109P',
  screenId: 'MSPTS109P',
  components: {},
  props: {
    popupObj: { type: Object, default: null }
  },
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    // this.fn_init();
  },
  mounted() {
    this.$bizUtil.insSrnLog('MSPTS109P')
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      /***********************************************************************************
       * AS-IS 전역 변수
       ***********************************************************************************/
      ds_detail: Object.assign({}, defaultDsDetail),
      testSwitch: true,

      Chk_mkt_all: false, // 스위치 전체 체크
      mktSwtcSs: '', // 문자 여부
      mktSwtcEMail: '', // 이메일 여부
      mktSwtcTel: '', // 전화 여부
      mktSwtcMail: '' // 주소 여부
    }
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    fn_valdEndDate () {
      return this.ds_detail.valdEndDate = moment(new Date()).add(this.ds_detail.mktnCnsntTrm * 12, 'months').format('yyyy-MM-DD').toString()
    },//ASR240300447 / [사랑on신탁] 태블릿 전자신규매핑 및 프로그램 수정 / 2024.03.13
    //  mktSwtcTel: { // 마케팅 연락방식 : 전화
    //     set: function( bool ) {
    //       // this.ds_detail.mktnInvtTelYn = bool
    //       // this._mktSwtcTel = bool
    //       //this._mktSwtcTel = bool
    //       // return bool
    //     },
    //     get: function() {
    //       return ! TSCommUtil.gfn_isNull ( this.ds_detail.mktnInvtTelYn ) || this.ds_detail.mktnInvtTelYn == 'Y'  ? true : false
    //     }
    //   },
    //   mktSwtcSs: { // 마케팅 연락방식 : 문자
    //     set: function( bool ) {
    //       // this.ds_detail.mktnInvtSsYn = bool
    //       // this._mktSwtcSs = bool
    //       // return bool
    //     },
    //     get: function() {
    //       return ! TSCommUtil.gfn_isNull ( this.ds_detail.mktnInvtSsYn ) || this.ds_detail.mktnInvtSsYn == 'Y' ? true : false
    //     }
    //   },
    //   mktSwtcEMail: { // 마케팅 연락방식 : E-mail
    //     set: function( bool ) {
    //       // this.ds_detail.mktnInvtEmailYn = bool
    //       // this._mktSwtcEMail = bool
    //       // return bool
    //     },
    //     get: function() {
    //       return ! TSCommUtil.gfn_isNull ( this.ds_detail.mktnInvtEmailYn ) || this.ds_detail.mktnInvtEmailYn == 'Y' ? true : false
    //     }
    //   },
    //   mktSwtcMail: { // 마케팅 연락방식 : 우편
    //     set: function( bool ) {
    //       // this.ds_detail.mktnInvtMailYn = bool
    //       // this._mktSwtcMail = bool
    //       // return bool
    //     },
    //     get: function() {
    //       return ! TSCommUtil.gfn_isNull ( this.ds_detail.mktnInvtMailYn ) || this.ds_detail.mktnInvtMailYn == 'Y' ? true : false
    //     }
    //   },
    modal() {
      return this.$refs.modal
    },
    dsAvlPrd1() {
      // test
      let rtn = []
      // rtn.push({value: '5', text: '5년'});
      // rtn.push({value: '4', text: '4년'});
      // rtn.push({value: '3', text: '3년'});
      // rtn.push({value: '2', text: '2년'}); // 2024.03.12 / ASR240300447 / [사랑on신탁] 태블릿 전자신규매핑 및 프로그램 수정 
      rtn.push({ value: '1', text: '1년', disabled: false })
      return rtn
    },
    dsAvlPrd2() {
      let rtn = []
      rtn.push({ value: '2', text: '2년' })
      rtn.push({ value: '1', text: '1년' })
      return rtn
    },
    mktCustDealyn() {
      let sltVal = []
      sltVal.push({ value: 'Y', text: '거래고객' })
      sltVal.push({ value: 'N', text: '미거래고객' })
      return sltVal
    }
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {
    // mktSwtcTel:{
    //   handler(after, before) {
    //     this.ds_detail.mktnInvtTelYn = after == true ? 'Y' : 'N'
    //   }
    // },
    // mktSwtcSs:{
    //   handler(after, before) {
    //     this.ds_detail.mktnInvtSsYn = after == true ? 'Y' : 'N'
    //   }
    // },
    // mktSwtcEMail:{
    //   handler(after, before) {
    //     this.ds_detail.mktnInvtEmailYn = after == true ? 'Y' : 'N'
    //   }
    // },
    // mktSwtcMail:{
    //   handler(after, before) {
    //     this.ds_detail.mktnInvtMailYn = after == true ? 'Y' : 'N'
    //   }
    // },
    // 'ds_detail.mktnInvtTelYn': { // 마케팅권유전화여부
    //   handler(after, before) {
    //   }
    // },
    // 'ds_detail.mktnInvtSsYn': { // 마케팅권유SMS여부
    //   handler(after, before) {
    //   }
    // },
    // 'ds_detail.mktnInvtEmailYn': { // 마케팅권유이메일여부
    //   handler(after, before) {
    //   }
    // },
    // 'ds_detail.mktnInvtMailYn': { // 마케팅권유우편여부
    //   handler(after, before) {
    //   }
    // },
  },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /***************************************************************************************
     * Function명 : fn_init
     * 설명       : 변수 초기화
     *****************************************************************************************/
    fn_init() {
      // this.ds_detail = Object.assign({}, defaultDsDetail ),
      this.ds_detail.mktnCnsntTrm = '1' // 마케팅동의기간 ASR240300447 / [사랑on신탁] 태블릿 전자신규매핑 및 프로그램 수정 / 2024.03.13
      this.ds_detail.trCsYn = '' // TSCommUtil.gfn_isNull ( this.popupObj.ds_detail.trCsYn ) ? 'Y' : this.popupObj.ds_detail.trCsYn     // 거래고객여부
      this.ds_detail.mktnCnsntDate = this.popupObj.ds_detail.mktnCnsntDate // 마케팅 동의일자(=sysdate)
      this.ds_detail.csId = this.popupObj.ds_detail.csId
      this.ds_detail.newMemberYn = this.popupObj.ds_detail.newMemberYn // 신규고객 여부
      this.ds_detail.mktnInvtTelYn = this.popupObj.ds_detail.mktnInvtTelYn // 마케팅권유전화여부
      this.ds_detail.mktnInvtSsYn = this.popupObj.ds_detail.mktnInvtSsYn // 마케팅권유SMS여부
      this.ds_detail.mktnInvtEmailYn = this.popupObj.ds_detail.mktnInvtEmailYn // 마케팅권유이메일여부
      this.ds_detail.mktnInvtMailYn = this.popupObj.ds_detail.mktnInvtMailYn // 마케팅권유우편여부

      // switch
      this.mktSwtcTel = TSCommUtil.gfn_isNull(this.popupObj.ds_detail.mktnInvtTelYn) ? false : this.popupObj.ds_detail.mktnInvtTelYn == 'Y' ? true : false // 마케팅권유전화여부
      this.mktSwtcSs = TSCommUtil.gfn_isNull(this.popupObj.ds_detail.mktnInvtSsYn) ? false : this.popupObj.ds_detail.mktnInvtSsYn == 'Y' ? true : false // 마케팅권유SMS여부
      this.mktSwtcEMail = TSCommUtil.gfn_isNull(this.popupObj.ds_detail.mktnInvtEmailYn) ? false : this.popupObj.ds_detail.mktnInvtEmailYn == 'Y' ? true : false // 마케팅권유이메일여부
      this.mktSwtcMail = TSCommUtil.gfn_isNull(this.popupObj.ds_detail.mktnInvtMailYn) ? false : this.popupObj.ds_detail.mktnInvtMailYn == 'Y' ? true : false // 마케팅권유우편여부


      // 전체동의
      this.fn_changeCobMktCnvs()

    },

    /***************************************************************************************
     * Function명 : fn_onClickMktAll
     * 설명       : 마케팅 전체 동의 클릭 이벤트
     *****************************************************************************************/
    fn_onClickMktAll() {
      if (!this.Chk_mkt_all) {
        this.ds_detail.mktnInvtTelYn = 'Y' // 마케팅권유전화여부
        this.ds_detail.mktnInvtSsYn = 'Y' // 마케팅권유SMS여부
        this.ds_detail.mktnInvtEmailYn = 'Y' // 마케팅권유이메일여부
        this.ds_detail.mktnInvtMailYn = 'Y' // 마케팅권유우편여부

        this.mktSwtcTel = true
        this.mktSwtcSs = true
        this.mktSwtcEMail = true
        this.mktSwtcMail = true
      } else {
        this.ds_detail.mktnInvtTelYn = 'N' // 마케팅권유전화여부
        this.ds_detail.mktnInvtSsYn = 'N' // 마케팅권유SMS여부
        this.ds_detail.mktnInvtEmailYn = 'N' // 마케팅권유이메일여부
        this.ds_detail.mktnInvtMailYn = 'N' // 마케팅권유우편여부

        this.mktSwtcTel = false
        this.mktSwtcSs = false
        this.mktSwtcEMail = false
        this.mktSwtcMail = false
      }
    },

    /**********************************************************************************
     * Function명 : fn_changeCobMktCnvs
     * 설명       : 마케팅 수신 여부에 따른 전체 동의 change
     **********************************************************************************/
    fn_changeCobMktCnvs() {
      if (this.mktSwtcTel && this.mktSwtcSs && this.mktSwtcEMail && this.mktSwtcMail) {
        //전체 동의일 경우
        this.Chk_mkt_all = true
      } else {
        this.Chk_mkt_all = false
      }
    },

    // /**********************************************************************************
    //  * Function명 : fn_setMktnCnsntTrm
    //  * 설명       : 거래여부 change - 거래여부에 따른 유효기간 설정
    //  **********************************************************************************/
    // fn_setMktnCnsntTrm() {
    //   //this.ds_detail.mktnCnsntTrm = '' //거래여부에 따른 유효기간 설정
    //   //this.ds_detail.valdEndDate = '' //종료일자
    // }, // ASR240300447 / [사랑on신탁] 태블릿 전자신규매핑 및 프로그램 수정 / 2024.03.13

    /**********************************************************************************
     * Function명 : fn_changeMktnCnsntTrm
     * 설명       : 유효기간 change(현재 날짜에서 유효기간 만큼 더한 후 하루를 뺀다.)
     **********************************************************************************/
    fn_changeMktnCnsntTrm(val) {
      // var calcDate
      this.ds_detail.valdEndDate = moment(new Date())
        .add(val * 12, 'months')
        //.add(-1, 'days') // ASR240300447 / [사랑on신탁] 태블릿 전자신규매핑 및 프로그램 수정 / 2024.03.13
        .format('yyyy-MM-DD')
        .toString()
    },

    /**********************************************************************************
     * Function명 : fn_confirm
     * 설명       : 확인 클릭
     **********************************************************************************/
    fn_confirm() {
      if (!this.mktSwtcTel && !this.mktSwtcSs && !this.mktSwtcEMail && !this.mktSwtcMail) {
        this.fn_AlertPopup('연락방식을 하나이상 선택하세요.')
        return
      }
      if (this.ds_detail.trCsYn == '') {
        this.fn_AlertPopup('거래여부를 선택하세요.')
        return
      }
      // if (this.ds_detail.mktnCnsntTrm == '') {
      //   this.fn_AlertPopup('유효기간을 선택하세요.')
      //   return
      // } // ASR240300447 / [사랑on신탁] 태블릿 전자신규매핑 및 프로그램 수정 / 2024.03.13
      console.log(TSCommUtil.gfn_getNum(this.ds_detail.mktnCnsntTrm))

      // if (this.ds_detail.trCsYn == 'N' && TSCommUtil.gfn_getNum(this.ds_detail.mktnCnsntTrm) > 2) {
      //   this.fn_AlertPopup('미거래고객은 유효기간 1~2년 中 선택되어야 합니다.')
      //   return
      // } // ASR240300447 / [사랑on신탁] 태블릿 전자신규매핑 및 프로그램 수정 / 2024.03.13

      // 메인 페이지 부모 객체에 접근 변경
      // this.popupObj.ds_detail.mktnInvtTelYn = this.ds_detail.mktnInvtTelYn  // 마케팅권유전화여부
      // this.popupObj.ds_detail.mktnInvtSsYn = this.ds_detail.mktnInvtSsYn  // 마케팅권유SMS여부
      // this.popupObj.ds_detail.mktnInvtEmailYn = this.ds_detail.mktnInvtEmailYn // 마케팅권유이메일여부
      // this.popupObj.ds_detail.mktnInvtMailYn = this.ds_detail.mktnInvtMailYn  // 마케팅권유우편여부
      // this.popupObj.ds_detail.mktnCnsntTrm = this.ds_detail.mktnCnsntTrm // 마케팅동의기간
      // this.popupObj.ds_detail.valdEndDate = this.ds_detail.valdEndDate   // 유효종료일자
      // this.popupObj.ds_detail.trCsYn = this.ds_detail.trCsYn // 거래고객여부
      // this.popupObj.ds_detail.mktnCnsntDate = this.ds_detail.mktnCnsntDate   // 마케팅 동의일자(=sysdate)
      // this.popupObj.ds_detail.csId = this.ds_detail.csId
      // this.popupObj.ds_detail.newMemberYn = this.ds_detail.newMemberYn  // 신규고객 여부

      // 컴포넌트 재사용을 위해 메인 콜백에서 처리
      const rtnObj = {
        btnClickType: 'confirm', // 팝업페이지 버튼 이벤트 종류
        mktnInvtTelYn: this.mktSwtcTel == true ? 'Y' : 'N', // 마케팅권유전화여부
        mktnInvtSsYn: this.mktSwtcSs == true ? 'Y' : 'N', // 마케팅권유SMS여부
        mktnInvtEmailYn: this.mktSwtcEMail == true ? 'Y' : 'N', // 마케팅권유이메일여부
        mktnInvtMailYn: this.mktSwtcMail == true ? 'Y' : 'N', // 마케팅권유우편여부
        mktnCnsntTrm: this.ds_detail.mktnCnsntTrm, // 마케팅동의기간
        valdEndDate: this.ds_detail.valdEndDate, // 유효종료일자
        trCsYn: this.ds_detail.trCsYn, // 거래고객여부
        mktnCnsntDate: this.getStore('tsStore').getters.getBasInfo.data.busyDate // 마케팅 동의일자(=sysdate)
      }

      // this.fn_init();
      this.$emit('ts-popup-callback', 4, rtnObj)
      this.modal.close()
    },

    /*-----------------------------------------------------------------------------------------------------*/
    /******************************************************************************
     * Function명 : fn_AlertPopup
     * 설명       : alert popup 호출(공통)
     ******************************************************************************/

    fn_AlertPopup(msg) {
      let t_popupObj = {
        confirm: false,
        content: msg
      }
      this.$emit('ts-alert-popup', 0, t_popupObj)
    },
    /******************************************************************************
     * Function명 : fn_Open
     * 설명       : modal open
     ******************************************************************************/

    fn_Open() {
      this.fn_init()
      this.modal.open()
    },

    /******************************************************************************
     * Function명 : fn_Close
     * 설명       : modal close
     ******************************************************************************/

    fn_Close() {
      // 메인 페이지 부모 객체에 접근 변경
      // this.popupObj.ds_detail.mktnInvtTelYn = 'N' // 마케팅권유전화여부
      // this.popupObj.ds_detail.mktnInvtSsYn = 'N' // 마케팅권유SMS여부
      // this.popupObj.ds_detail.mktnInvtEmailYn = 'N' // 마케팅권유이메일여부
      // this.popupObj.ds_detail.mktnInvtMailYn = 'N' // 마케팅권유우편여부
      // this.popupObj.ds_detail.mktnCnsntTrm = '' // 마케팅동의기간
      // this.popupObj.ds_detail.valdEndDate = '' // 유효종료일자
      // this.popupObj.ds_detail.trCsYn = '' // 거래고객여부
      // this.popupObj.ds_detail.mktnCnsntDate = '' // 마케팅 동의일자(=sysdate)

      // 컴포넌트 재사용을 위해 메인 콜백에서 처리
      const rtnObj = {
        btnClickType: 'cancel', // 팝업페이지 버튼 이벤트 종류
        mktnInvtTelYn: 'N', // 마케팅권유전화여부
        mktnInvtSsYn: 'N', // 마케팅권유SMS여부
        mktnInvtEmailYn: 'N', // 마케팅권유이메일여부
        mktnInvtMailYn: 'N', // 마케팅권유우편여부
        mktnCnsntTrm: '', // 마케팅동의기간
        valdEndDate: '', // 유효종료일자
        trCsYn: '', // 거래고객여부
        mktnCnsntDate: '' // 마케팅 동의일자(=sysdate)
      }
      this.$emit('ts-popup-callback', 4, rtnObj)
      this.modal.close()
    }
  }
}
</script>
<style scoped></style>
