/*
 * 업무구분: 신탁>고객/상품>고객정보등록>개인고객등록>FC조회_P
 * 화 면 명: MSPTS115P
 * 화면설명: FC조회 POPUP
 * 작 성 일: 2023.04.04
 * 작 성 자: 지재호
 */
<template>
    <!-- popup -->
    <mo-modal class="fts-modal large" ref="modal" title="FC조회">
      <template>
        <div class="wrap-modalcontents">
          <div class="input-top">
            <div class="column w100p gap12">
              <div class="row w100p">
                <div class="left">
                  <div class="wrap-input row">
                    <label> 사원번호 </label>
                    <mo-decimal-field 
                      mask="########"
                      numeric
                      clearable placeholder="입력하세요" v-model="ds_search.fcEmno" 
                    />
                  </div>
                  <div class="wrap-input row">
                    <label> 성명 </label>
                    <mo-text-field clearable placeholder="입력하세요" v-model="ds_search.fcNm" />
                  </div>
                </div>
                <div class="right">
                  <div class="wrap-button row">
                    <mo-button @click="fn_init"> 초기화 </mo-button>
                    <mo-button primary @click="fn_searchList"> 조회 </mo-button>
                  </div>
                </div>
              </div>
              <!-- 현업 요청으로 실명번호 삭제 -->
              <div class="row w100p" v-if="false">
                <div class="left w100p">
                  <div class="wrap-input row">
                    <label> 실명번호 </label>
                    <mo-decimal-field class="w130" 
                      v-model="rcnoOne" 
                      placeholder="앞 6자리" 
                      mask="######"
                      numeric
                      @keyup="fn_SearchValid($event, 'edt_rcnoOne')" 
                      @paste="fn_SearchValid($event, 'P')"
                      ref="edt_rcnoOne" 
                    />

                    <m-trans-key-input placeholder="뒤 7자리" class="w80"
                      v-if="isMobile"
                      v-model="rcnoTwo" 
                      type="numberMax7"
                      dialog="Y"
                      start="1" 
                      end="-1"
                      :isClear="lv_isClear"
                      @masked-txt="fn_SetMaskedTxt"         
                      ref="edt_rcnoTwo" 
                    />
                    <mo-text-field class="w130" 
                      v-else
                      v-model="rcnoTwo" 
                      type="password" 
                      mask="#######" 
                      numeric
                      placeholder="뒤 7자리" 
                      @keyup="fn_SearchValid($event, 'edt_rcnoTwo')" 
                      ref="edt_rcnoTwo" 
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="wrap-table mt16 h-scroll-250">
            <v-data-table
              :headers="headers"
              :items="employeeList_imsi"
              class="table row-type aC"
            >
            </v-data-table>
          </div> -->
            

          <div class="wrap-table mt16 h-scroll-250">
            <table class="table row-type aC">
              <thead>
                <tr>
                  <th class="w40"> </th>
                  <th> 사원번호 </th>
                  <th> 성명 </th>
                  <th> 지점 </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="(row, idx) in lv_showList" :key="idx" 
                  :class="{'checked': lv_selected == (idx)}"
                  @click="lv_selected = String(idx)"
                >
                  <td>
                    <mo-radio v-model="lv_selected" :value="`${String(idx)}`"></mo-radio>
                  </td>
                  <td>
                    {{row.fcEmno}}
                  </td>
                  <td>
                    {{row.fcNm}}
                  </td>
                  <td>
                    {{row.plzaNm}}
                  </td>
                </tr>
              </tbody>
            </table>
            
          </div>
          <div class="wrap-pagination mt0">
            <mo-pagination v-if="lv_page.totalItems > 0"
              :pagination="lv_page"
              @move="fn_PageChange"
            />
          </div>
        </div>
       
      </template>
      <template slot="action">
        <div class="wrap-button button-page-bottom row">
          <mo-button point size="large" @click="closeModal(1)">닫기</mo-button>
          <mo-button primary size="large" @click="fn_confirm">확인</mo-button>
        </div>
      </template>
    </mo-modal>
</template>
<script>
import TSServiceUtil from './comm/TSServiceUtil';

const defaultDsSearch = {
  fcEmno: '',
  fcNm: '',
  rcno: '',
}

const defaultDsDetail = {
  fcEmno: '',
  fcNm: '',
}

/***********************************************************************************
 * 공통 라이브러리 INCLUDE 영역	                                                   *
 ***********************************************************************************/
import TSCommUtil from '~/src/ui/ts/comm/TSCommUtil'

export default {
  /***********************************************************************************
   * Vue 파일 속성 정의 영역	                                                       *
   ***********************************************************************************/
  name: "MSPTS103P",
  screenId: "MSPTS103P",
  props: {
    popupObj: {type:Object, default:null},
  },
  components: {},
  /***********************************************************************************
   * Life Cycle 함수 정의 영역	                                                     *
   ***********************************************************************************/
  created() {
    //this.fn_AlertPopup()
  },
  mounted() {
    this.$bizUtil.insSrnLog("MSPTS103P");
  },
  /***********************************************************************************
   * 화면변수 선언 영역	                                                             *
   ***********************************************************************************/
  data() {
    return {
      headers: [{text:'순번'},{text:'사원번호'}
                ,{text:'이름'},{text:'branch'}
        ],
      lv_selected: null,
      g_invPosYn : 'N',
      // 보안키패드 관련
      isMobile: window.vue.getStore('deviceState').getters.getIsMobile,
      isMtrans: this.getStore('fsStore').getters.getState.isMtrans,
      lv_isClear: false,    // 비밀번호 보안키패드 초기화 
      lv_masked_rcnoTwo: '',    // 비밀번호 보안키패드 치환값 ****
      
      ds_search: Object.assign({}, defaultDsSearch),
      ds_detail: Object.assign({}, defaultDsDetail),
      rcnoOne: '', // 실명번호 첫번째 
      rcnoTwo: '', // 실명번호 두번째 
      
      // 조회결과
      lv_searchList: [],
      lv_showList: [],

      // 리스트 선택값
      lv_selected: null,

      // 페이징
      lv_page: {
        rowsPerPage: 10,
        currentPage: 1,
        totalPages: 0,
        totalItems: 0,
      },
      
      eaiCommObj: TSCommUtil.gfn_eaiCommObj(), // Contents Model 
    };
  },
  /***********************************************************************************
   * Computed 함수 정의 영역                                                         *
   ***********************************************************************************/
  computed: {
    // 실명번호 두번째 검증 비교할값
    valid_rcnoTwo() {
      if ( this.isMobile ) { // 보안키패드 
        return this.lv_masked_rcnoTwo
      } else { // 보안키패드가 아닌경우
        return this.rcnoTwo
      }
    },
    // modal
    modal() {
      return this.$refs.modal
    },
    
  },
  /***********************************************************************************
   * watch 함수 정의 영역                                                         *
   ***********************************************************************************/
  watch: {
    rcnoOne: function() {
        this.ds_search.rcno = this.rcnoOne.toString() + this.rcnoTwo.toString()
    },
    rcnoTwo: function() {
      this.ds_search.rcno = this.rcnoOne.toString() + this.rcnoTwo.toString()
    },
 },
  /***********************************************************************************
   * 사용자 함수 정의 영역                                                           *
   ***********************************************************************************/
  methods: {
    /******************************************************************************
      * Function명 : fn_SetMaskedTxt
      * 설명       : 보안키패드 체크
      ******************************************************************************/
      fn_SetMaskedTxt(val) {
        this.lv_masked_rcnoTwo = val
        if ( ! TSCommUtil.gfn_isNull( TSCommUtil.gfn_trim(val) ) )
          this.$refs['edt_rcnoTwo'].$el.classList.remove('error')
      },
      
    /******************************************************************************
     * Function명 : fn_SearchValid
     * 설명       : 필수값 검증 후 조회 
     ******************************************************************************/
    fn_SearchValid (event, flag) {
      console.log('fn_SearchValid.....')

      let t_rcno
      if( flag == 'P' ) {
        t_rcno = (event.clipboardData || window.clipboardData).getData('text').replace(/[^0-9]/g, '').substr(0,13)
        let t_rcnoOne = t_rcno.substr(0,6)
        let t_rcnoTwo = this.isMobile ? '' : t_rcno.substr(6,7)
        this.rcnoOne = t_rcnoOne
        this.rcnoTwo = t_rcnoTwo

        if( TSCommUtil.gfn_length(t_rcno) == 13 ) {
          if( !TSCommUtil.gfn_isNum(event.key) && event.key !='Enter' && event.key != 'v' && !this.isMobile ) {
            return 
          }
          // this.fn_searchList()
        }

      } else if ( flag == 'edt_rcnoOne' ) {
        t_rcno = this.rcnoOne
        if( TSCommUtil.gfn_length(t_rcno) == 6 ) {
          this.$refs['edt_rcnoTwo'].focus()
        }
      } else if ( flag == 'edt_rcnoTwo' ) { 
        t_rcno = this.rcnoTwo
        if( TSCommUtil.gfn_length(t_rcno) == 7 ) {
          if( !TSCommUtil.gfn_isNum(event.key) && event.key !='Enter' && event.key != 'v' && !this.isMobile ) {
            return 
          }
          // this.fn_searchList()
        }
      }
    },
    
    setChoiceData ( event, row ) {
      console.log(event)
      console.log(row)
      this.ds_detail.fcEmno = row.empNum 
      this.ds_detail.fcNm = row.empName
      this.lv_selected = row
      //  @click="lv_isFund = String(idx+1)
    },

    fn_init () {
      this.ds_search = Object.assign({}, defaultDsSearch)
      this.ds_detail = Object.assign({}, defaultDsDetail)
      this.employeeList = []
      
      if(!TSCommUtil.gfn_isNull(this.popupObj.invPosYn) ){
        this.g_invPosYn = this.popupObj.invPosYn 
      }

      // 조회결과
      this.lv_searchList            = []
      this.lv_showList              = []
      this.lv_selected              = null
      this.lv_page = {
        rowsPerPage: 10,
        currentPage: 1,
        totalPages: 0,
        totalItems: 0,
      }
    },

    // modal
    openModal(type) {
      switch (type) {
        case 1:
          this.modal.open();
          break;
      }
    },
    closeModal(type) {
      switch (type) {
        case 1:
          this.modal.close();
          break;
      }
    },

    fn_Open() {
      console.log(this.popupObj)
      // TODO : init 함수는 PO 붙이고
      this.fn_init()
      this.modal.open()
    },
    fn_Close() {
      this.modal.close()
    },

    // 부모 함수 호출
    fn_AlertPopup() {
        let t_popupObj = {
          confirm: false,
          content: '테스트.'
        }
        this.$emit('ts-alert-popup', 0, t_popupObj)
    },

    fn_searchList() {
      const empno    = this.ds_search.fcEmno
      const emp_nm   = this.ds_search.fcNm
      const emp_rcno = this.rcno
      ///////////////////////////
      if( ( empno.trim().length  )  + ( emp_nm.trim().length )  < 1 ) {
        this.$emit('ts-alert-popup', 0, {confirm: false, content: '사원번호, 사원명, 실명번호 중 하나를 입력하여 주십시요!'})
        return false;
      }
      if(  empno.trim().length > 0 && empno.trim().length < 2) {
        this.$emit('ts-alert-popup', 0, {confirm: false, content: '사원번호는 2글자 이상 입력하셔야 합니다.'})
        return false;
      } else if( emp_nm.trim().length   > 0 &&  emp_nm.trim().length   < 2) {	
        this.$emit('ts-alert-popup', 0, {confirm: false, content: '사원명을  2글자 이상 입력하셔야 합니다.'})
        return false;
      }
      
      //조회용 dataset을 초기화 해준다.
      this.lv_showList = []
      

      //서버에 호출 할 정보를 셋팅 해준다.
      // var sSvcID        = "selectTPUP95290List";
      // var sURL          = "svc::selectTPUP95290List.do";		// svc = http://localhost:8080/
      // var sInDatasets   = "tpup95290=ds_search:";
      // var sOutDatasets  = "ds_list=tpup95290List";
      // var sArgument     = "";
      // var sCallbackFunc = "fn_callBack";
      // this.gfn_callService(sSvcID, sURL, sInDatasets ,sOutDatasets , sArgument , sCallbackFunc);

      
      this.eaiCommObj.lv_vm = this
      this.eaiCommObj.auth = 'S'
      this.eaiCommObj.commHeaderVO.eaiId = 'C391_F10600225_S'
      this.eaiCommObj.params = Object.assign({}, this.ds_search) 
      TSServiceUtil.invokeInDirect(this.eaiCommObj, this.fn_callBack)
    },

    fn_confirm () {

      if( this.lv_showList[this.lv_selected].invPosYn == "N" ) {
        this.$emit('ts-alert-popup', 0, {confirm: false, content: '해당 직원은 신탁상품 권유자격이 불충분하여 권유자로 입력할 수 없습니다.'})
        return false
      }

      if ( TSCommUtil.gfn_isNull ( this.lv_selected ) ) {
        this.$emit('ts-alert-popup', 0, {confirm: false, content: 'FC 직원을 선택하여 주세요.'})
        return false
      }

      this.ds_detail.fcEmno = this.lv_showList[this.lv_selected].fcEmno
      this.ds_detail.fcNm = this.lv_showList[this.lv_selected].fcNm
      this.$emit('ts-popup-callback', 7, this.ds_detail)
      this.modal.close();
    },
    
    fn_callBack ( res ) {
      const resData = Object.assign({}, res.data)

      if ( TSCommUtil.gfn_trim(resData.errorCode) != '' ) {
        this.$emit('ts-alert-popup', 0, {confirm: false, content: resData.errorMsg})
        return false
      }

      let t_data = resData.tpup95290
      if( TSCommUtil.gfn_length(t_data) > 0 ) {
        this.lv_searchList = t_data

        this.lv_page.totalPages = t_data.length / 10
        this.lv_page.totalItems = t_data.length

        this.fn_PageChange(1)

      } else {
        console.log('입력하신 조건에 자료가 존재하지 않습니다.')
        // t_popupObj = {content: '입력하신 조건에 자료가 존재하지 않습니다.'}
        // this.fn_CallAlert(t_popupObj)
      }
    },

    /******************************************************************************
     * Function명 : fn_PageChange
     * 설명       : 페이징 처리
     ******************************************************************************/
    fn_PageChange(pageNum) {
      console.log('fn_PageChange.....')

      this.lv_showList = []

      let strNum = (pageNum - 1) * this.lv_page.rowsPerPage
      let endNum = pageNum * this.lv_page.rowsPerPage

      for(let i=strNum; i<endNum; i++) {
        if( this.lv_searchList[i] ) {
          this.lv_showList.push(this.lv_searchList[i])
        }
      }
    },
    
  }
};
</script>
<style scoped>
</style>